export class ParametrosNoAdministrables {
  public static Sexo = ['Masculino', 'Femenino', '']

  public static ValoresGenericos = {
    PathBaseName: '',
    /* urlServicio: "https://healthcare-global-functions-dev.azurewebsites.net", */
    /*urlServicio: "https://healthcare-global-test-fauth.azurewebsites.net",*/
    //urlServicio: 'https://laus-global-prd-fauth.azurewebsites.net',
    //urlServicio: "https://api.dev.temposalud.com/global-functions",
    urlServicio:
      'https://' + process.env.REACT_APP_MAIN_URL + '/global-functions',
    //urlServicio: "https://tempo-global-prd-fauth.azurewebsites.net",

    //urlServicioUsuario: process.env.REACT_APP_FUSUARIO + '',
    //urlServicioTriaje: process.env.REACT_APP_FTRIAJE + '',
    //urlServicioDashboard: process.env.REACT_APP_FDASHBOARD + '',
    //urlServicioUsuario: 'https://api.dev.temposalud.com/user',
    urlServicioUsuario: 'https://' + process.env.REACT_APP_MAIN_URL + '/user',
    //urlServicioTriaje: 'https://api.dev.temposalud.com/triaje',
    urlServicioTriaje: 'https://' + process.env.REACT_APP_MAIN_URL + '/triaje',
    //urlServicioDashboard: 'https://api.dev.temposalud.com/dashboard',
    urlServicioDashboard:
      'https://' + process.env.REACT_APP_MAIN_URL + '/dashboard',

    Seleccione: 'Seleccione',
    NA: 'N/A',
    RutaTerminos: 'https://temposalud.com/terms/',
    RutaPoliticas: 'https://temposalud.com/politicas/',
    urlServicioHome:
      'https://temposalud.com/tempo_covit/api/4/config.php?action=company_get_home_buttons_app&token=',
    urlServicioHomeBanner:
      'https://temposalud.com/tempo_covit/api/4/config.php?action=get_home_banner&token=',

    ElementosPorPagina: 10,
    CantidadPaginasVisiblesPaginacion: 5,
  }

  public static Departamentos = [
    'Amazonas',
    'Áncash',
    'Apurímac',
    'Arequipa',
    'Ayacucho',
    'Cajamarca',
    'Callao',
    'Cusco',
    'Huancavelica',
    'Huánuco',
    'Ica',
    'Junín',
    'La Libertad',
    'Lambayeque',
    'Lima',
    'Loreto',
    'Madre de Dios',
    'Moquegua',
    'Pasco',
    'Piura',
    'Puno',
    'San Martín',
    'Tacna',
    'Tumbes',
    'Ucayali',
  ]

  public static Provincias = [
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas' },
    { Departamento: 'Amazonas', Provincia: 'Bagua' },
    { Departamento: 'Amazonas', Provincia: 'Bongará' },
    { Departamento: 'Amazonas', Provincia: 'Condorcanqui' },
    { Departamento: 'Amazonas', Provincia: 'Luya' },
    { Departamento: 'Amazonas', Provincia: 'Rodríguez de Mendoza' },
    { Departamento: 'Amazonas', Provincia: 'Utcubamba' },
    { Departamento: 'Áncash', Provincia: 'Huaraz' },
    { Departamento: 'Áncash', Provincia: 'Aija' },
    { Departamento: 'Áncash', Provincia: 'Antonio Raymondi' },
    { Departamento: 'Áncash', Provincia: 'Asunción' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz' },
    { Departamento: 'Áncash', Provincia: 'Carlos Fermín Fitzcarrald' },
    { Departamento: 'Áncash', Provincia: 'Casma' },
    { Departamento: 'Áncash', Provincia: 'Corongo' },
    { Departamento: 'Áncash', Provincia: 'Huari' },
    { Departamento: 'Áncash', Provincia: 'Huarmey' },
    { Departamento: 'Áncash', Provincia: 'Huaylas' },
    { Departamento: 'Áncash', Provincia: 'Mariscal Luzuriaga' },
    { Departamento: 'Áncash', Provincia: 'Ocros' },
    { Departamento: 'Áncash', Provincia: 'Pallasca' },
    { Departamento: 'Áncash', Provincia: 'Pomabamba' },
    { Departamento: 'Áncash', Provincia: 'Recuay' },
    { Departamento: 'Áncash', Provincia: 'Santa' },
    { Departamento: 'Áncash', Provincia: 'Yungay' },
    { Departamento: 'Apurímac', Provincia: 'Abancay' },
    { Departamento: 'Apurímac', Provincia: 'Andahuaylas' },
    { Departamento: 'Apurímac', Provincia: 'Antabamba' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes' },
    { Departamento: 'Apurímac', Provincia: 'Cotabambas' },
    { Departamento: 'Apurímac', Provincia: 'Chincheros' },
    { Departamento: 'Apurímac', Provincia: 'Grau' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa' },
    { Departamento: 'Arequipa', Provincia: 'Camaná' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí' },
    { Departamento: 'Arequipa', Provincia: 'Castilla' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma' },
    { Departamento: 'Arequipa', Provincia: 'Condesuyos' },
    { Departamento: 'Arequipa', Provincia: 'Islay' },
    { Departamento: 'Arequipa', Provincia: 'La Unión' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga' },
    { Departamento: 'Ayacucho', Provincia: 'Cangallo' },
    { Departamento: 'Ayacucho', Provincia: 'Huanca Sancos' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas' },
    { Departamento: 'Ayacucho', Provincia: 'Parinacochas' },
    { Departamento: 'Ayacucho', Provincia: 'Pàucar del Sara Sara' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre' },
    { Departamento: 'Ayacucho', Provincia: 'Víctor Fajardo' },
    { Departamento: 'Ayacucho', Provincia: 'Vilcas Huamán' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca' },
    { Departamento: 'Cajamarca', Provincia: 'Cajabamba' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín' },
    { Departamento: 'Cajamarca', Provincia: 'Chota' },
    { Departamento: 'Cajamarca', Provincia: 'Contumazá' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo' },
    { Departamento: 'Cajamarca', Provincia: 'Hualgayoc' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén' },
    { Departamento: 'Cajamarca', Provincia: 'San Ignacio' },
    { Departamento: 'Cajamarca', Provincia: 'San Marcos' },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel' },
    { Departamento: 'Cajamarca', Provincia: 'San Pablo' },
    { Departamento: 'Cajamarca', Provincia: 'Santa Cruz' },
    { Departamento: 'Callao', Provincia: 'Prov. Const. del Callao' },
    { Departamento: 'Cusco', Provincia: 'Cusco' },
    { Departamento: 'Cusco', Provincia: 'Acomayo' },
    { Departamento: 'Cusco', Provincia: 'Anta' },
    { Departamento: 'Cusco', Provincia: 'Calca' },
    { Departamento: 'Cusco', Provincia: 'Canas' },
    { Departamento: 'Cusco', Provincia: 'Canchis' },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas' },
    { Departamento: 'Cusco', Provincia: 'Espinar' },
    { Departamento: 'Cusco', Provincia: 'La Convención' },
    { Departamento: 'Cusco', Provincia: 'Paruro' },
    { Departamento: 'Cusco', Provincia: 'Paucartambo' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi' },
    { Departamento: 'Cusco', Provincia: 'Urubamba' },
    { Departamento: 'Huancavelica', Provincia: 'Huancavelica' },
    { Departamento: 'Huancavelica', Provincia: 'Acobamba' },
    { Departamento: 'Huancavelica', Provincia: 'Angaraes' },
    { Departamento: 'Huancavelica', Provincia: 'Castrovirreyna' },
    { Departamento: 'Huancavelica', Provincia: 'Churcampa' },
    { Departamento: 'Huancavelica', Provincia: 'Huaytará' },
    { Departamento: 'Huancavelica', Provincia: 'Tayacaja' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco' },
    { Departamento: 'Huánuco', Provincia: 'Ambo' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo' },
    { Departamento: 'Huánuco', Provincia: 'Huacaybamba' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes' },
    { Departamento: 'Huánuco', Provincia: 'Leoncio Prado' },
    { Departamento: 'Huánuco', Provincia: 'Marañón' },
    { Departamento: 'Huánuco', Provincia: 'Pachitea' },
    { Departamento: 'Huánuco', Provincia: 'Puerto Inca' },
    { Departamento: 'Huánuco', Provincia: 'Lauricocha' },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca' },
    { Departamento: 'Ica', Provincia: 'Ica' },
    { Departamento: 'Ica', Provincia: 'Chincha' },
    { Departamento: 'Ica', Provincia: 'Nasca' },
    { Departamento: 'Ica', Provincia: 'Palpa' },
    { Departamento: 'Ica', Provincia: 'Pisco' },
    { Departamento: 'Junín', Provincia: 'Huancayo' },
    { Departamento: 'Junín', Provincia: 'Concepción' },
    { Departamento: 'Junín', Provincia: 'Chanchamayo' },
    { Departamento: 'Junín', Provincia: 'Jauja' },
    { Departamento: 'Junín', Provincia: 'Junín' },
    { Departamento: 'Junín', Provincia: 'Satipo' },
    { Departamento: 'Junín', Provincia: 'Tarma' },
    { Departamento: 'Junín', Provincia: 'Yauli' },
    { Departamento: 'Junín', Provincia: 'Chupaca' },
    { Departamento: 'La Libertad', Provincia: 'Trujillo' },
    { Departamento: 'La Libertad', Provincia: 'Ascope' },
    { Departamento: 'La Libertad', Provincia: 'Bolívar' },
    { Departamento: 'La Libertad', Provincia: 'Chepén' },
    { Departamento: 'La Libertad', Provincia: 'Julcán' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco' },
    { Departamento: 'La Libertad', Provincia: 'Pacasmayo' },
    { Departamento: 'La Libertad', Provincia: 'Pataz' },
    { Departamento: 'La Libertad', Provincia: 'Sánchez Carrión' },
    { Departamento: 'La Libertad', Provincia: 'Santiago de Chuco' },
    { Departamento: 'La Libertad', Provincia: 'Gran Chimú' },
    { Departamento: 'La Libertad', Provincia: 'Virú' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo' },
    { Departamento: 'Lambayeque', Provincia: 'Ferreñafe' },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque' },
    { Departamento: 'Lima', Provincia: 'Lima' },
    { Departamento: 'Lima', Provincia: 'Barranca' },
    { Departamento: 'Lima', Provincia: 'Cajatambo' },
    { Departamento: 'Lima', Provincia: 'Canta' },
    { Departamento: 'Lima', Provincia: 'Cañete' },
    { Departamento: 'Lima', Provincia: 'Huaral' },
    { Departamento: 'Lima', Provincia: 'Huarochirí' },
    { Departamento: 'Lima', Provincia: 'Huaura' },
    { Departamento: 'Lima', Provincia: 'Oyón' },
    { Departamento: 'Lima', Provincia: 'Yauyos' },
    { Departamento: 'Loreto', Provincia: 'Maynas' },
    { Departamento: 'Loreto', Provincia: 'Alto Amazonas' },
    { Departamento: 'Loreto', Provincia: 'Loreto' },
    { Departamento: 'Loreto', Provincia: 'Mariscal Ramón Castilla' },
    { Departamento: 'Loreto', Provincia: 'Requena' },
    { Departamento: 'Loreto', Provincia: 'Ucayali' },
    { Departamento: 'Loreto', Provincia: 'Datem del Marañón' },
    { Departamento: 'Loreto', Provincia: 'Putumayo' },
    { Departamento: 'Madre de Dios', Provincia: 'Tambopata' },
    { Departamento: 'Madre de Dios', Provincia: 'Manu' },
    { Departamento: 'Madre de Dios', Provincia: 'Tahuamanu' },
    { Departamento: 'Moquegua', Provincia: 'Mariscal Nieto' },
    { Departamento: 'Moquegua', Provincia: 'General Sánchez Cerro' },
    { Departamento: 'Moquegua', Provincia: 'Ilo' },
    { Departamento: 'Pasco', Provincia: 'Pasco' },
    { Departamento: 'Pasco', Provincia: 'Daniel Alcides Carrión' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa' },
    { Departamento: 'Piura', Provincia: 'Piura' },
    { Departamento: 'Piura', Provincia: 'Ayabaca' },
    { Departamento: 'Piura', Provincia: 'Huancabamba' },
    { Departamento: 'Piura', Provincia: 'Morropón' },
    { Departamento: 'Piura', Provincia: 'Paita' },
    { Departamento: 'Piura', Provincia: 'Sullana' },
    { Departamento: 'Piura', Provincia: 'Talara' },
    { Departamento: 'Piura', Provincia: 'Sechura' },
    { Departamento: 'Puno', Provincia: 'Puno' },
    { Departamento: 'Puno', Provincia: 'Azángaro' },
    { Departamento: 'Puno', Provincia: 'Carabaya' },
    { Departamento: 'Puno', Provincia: 'Chucuito' },
    { Departamento: 'Puno', Provincia: 'El Collao' },
    { Departamento: 'Puno', Provincia: 'Huancané' },
    { Departamento: 'Puno', Provincia: 'Lampa' },
    { Departamento: 'Puno', Provincia: 'Melgar' },
    { Departamento: 'Puno', Provincia: 'Moho' },
    { Departamento: 'Puno', Provincia: 'San Antonio de Putina' },
    { Departamento: 'Puno', Provincia: 'San Román' },
    { Departamento: 'Puno', Provincia: 'Sandia' },
    { Departamento: 'Puno', Provincia: 'Yunguyo' },
    { Departamento: 'San Martín', Provincia: 'Moyobamba' },
    { Departamento: 'San Martín', Provincia: 'Bellavista' },
    { Departamento: 'San Martín', Provincia: 'El Dorado' },
    { Departamento: 'San Martín', Provincia: 'Huallaga' },
    { Departamento: 'San Martín', Provincia: 'Lamas' },
    { Departamento: 'San Martín', Provincia: 'Mariscal Cáceres' },
    { Departamento: 'San Martín', Provincia: 'Picota' },
    { Departamento: 'San Martín', Provincia: 'Rioja' },
    { Departamento: 'San Martín', Provincia: 'San Martín' },
    { Departamento: 'San Martín', Provincia: 'Tocache' },
    { Departamento: 'Tacna', Provincia: 'Tacna' },
    { Departamento: 'Tacna', Provincia: 'Candarave' },
    { Departamento: 'Tacna', Provincia: 'Jorge Basadre' },
    { Departamento: 'Tacna', Provincia: 'Tarata' },
    { Departamento: 'Tumbes', Provincia: 'Tumbes' },
    { Departamento: 'Tumbes', Provincia: 'Contralmirante Villar' },
    { Departamento: 'Tumbes', Provincia: 'Zarumilla' },
    { Departamento: 'Ucayali', Provincia: 'Coronel Portillo' },
    { Departamento: 'Ucayali', Provincia: 'Atalaya' },
    { Departamento: 'Ucayali', Provincia: 'Padre Abad' },
    { Departamento: 'Ucayali', Provincia: 'Purús' },
  ]

  public static Distritos = [
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Chachapoyas',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Asunción',
    },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Balsas' },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Cheto' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Chiliquin',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Chuquibamba',
    },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Granada' },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Huancas' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'La Jalca',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Leimebamba',
    },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Levanto' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Magdalena',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Mariscal Castilla',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Molinopampa',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Montevideo',
    },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Olleros' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'Quinjalca',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'San Francisco de Daguas',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Chachapoyas',
      Distrito: 'San Isidro de Maino',
    },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Soloco' },
    { Departamento: 'Amazonas', Provincia: 'Chachapoyas', Distrito: 'Sonche' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'Bagua' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'Aramango' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'Copallin' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'El Parco' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'Imaza' },
    { Departamento: 'Amazonas', Provincia: 'Bagua', Distrito: 'La Peca' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Jumbilla' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Chisquilla' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Churuja' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Corosha' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Cuispes' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Florida' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Jazan' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Recta' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'San Carlos' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Shipasbamba' },
    { Departamento: 'Amazonas', Provincia: 'Bongará', Distrito: 'Valera' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Bongará',
      Distrito: 'Yambrasbamba',
    },
    { Departamento: 'Amazonas', Provincia: 'Condorcanqui', Distrito: 'Nieva' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Condorcanqui',
      Distrito: 'El Cenepa',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Condorcanqui',
      Distrito: 'Río Santiago',
    },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Lamud' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Camporredondo' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Cocabamba' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Colcamar' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Conila' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Inguilpata' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Longuita' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Lonya Chico' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Luya' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Luya Viejo' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'María' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Ocalli' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Ocumal' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Pisuquia' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Providencia' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'San Cristóbal' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Luya',
      Distrito: 'San Francisco de Yeso',
    },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'San Jerónimo' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Luya',
      Distrito: 'San Juan de Lopecancha',
    },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Santa Catalina' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Santo Tomas' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Tingo' },
    { Departamento: 'Amazonas', Provincia: 'Luya', Distrito: 'Trita' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'San Nicolás',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Chirimoto',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Cochamal',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Huambo',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Limabamba',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Longar',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Mariscal Benavides',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Milpuc',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Omia',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Santa Rosa',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Totora',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Rodríguez de Mendoza',
      Distrito: 'Vista Alegre',
    },
    {
      Departamento: 'Amazonas',
      Provincia: 'Utcubamba',
      Distrito: 'Bagua Grande',
    },
    { Departamento: 'Amazonas', Provincia: 'Utcubamba', Distrito: 'Cajaruro' },
    { Departamento: 'Amazonas', Provincia: 'Utcubamba', Distrito: 'Cumba' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Utcubamba',
      Distrito: 'El Milagro',
    },
    { Departamento: 'Amazonas', Provincia: 'Utcubamba', Distrito: 'Jamalca' },
    {
      Departamento: 'Amazonas',
      Provincia: 'Utcubamba',
      Distrito: 'Lonya Grande',
    },
    { Departamento: 'Amazonas', Provincia: 'Utcubamba', Distrito: 'Yamon' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Huaraz' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Cochabamba' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Colcabamba' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Huanchay' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Independencia' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Jangas' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'La Libertad' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Olleros' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Pampas Grande' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Pariacoto' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Pira' },
    { Departamento: 'Áncash', Provincia: 'Huaraz', Distrito: 'Tarica' },
    { Departamento: 'Áncash', Provincia: 'Aija', Distrito: 'Aija' },
    { Departamento: 'Áncash', Provincia: 'Aija', Distrito: 'Coris' },
    { Departamento: 'Áncash', Provincia: 'Aija', Distrito: 'Huacllan' },
    { Departamento: 'Áncash', Provincia: 'Aija', Distrito: 'La Merced' },
    { Departamento: 'Áncash', Provincia: 'Aija', Distrito: 'Succha' },
    {
      Departamento: 'Áncash',
      Provincia: 'Antonio Raymondi',
      Distrito: 'Llamellin',
    },
    { Departamento: 'Áncash', Provincia: 'Antonio Raymondi', Distrito: 'Aczo' },
    {
      Departamento: 'Áncash',
      Provincia: 'Antonio Raymondi',
      Distrito: 'Chaccho',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Antonio Raymondi',
      Distrito: 'Chingas',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Antonio Raymondi',
      Distrito: 'Mirgas',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Antonio Raymondi',
      Distrito: 'San Juan de Rontoy',
    },
    { Departamento: 'Áncash', Provincia: 'Asunción', Distrito: 'Chacas' },
    { Departamento: 'Áncash', Provincia: 'Asunción', Distrito: 'Acochaca' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Chiquian' },
    {
      Departamento: 'Áncash',
      Provincia: 'Bolognesi',
      Distrito: 'Abelardo Pardo Lezameta',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Bolognesi',
      Distrito: 'Antonio Raymondi',
    },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Aquia' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Cajacay' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Canis' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Colquioc' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Huallanca' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Huasta' },
    {
      Departamento: 'Áncash',
      Provincia: 'Bolognesi',
      Distrito: 'Huayllacayan',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Bolognesi',
      Distrito: 'La Primavera',
    },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Mangas' },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Pacllon' },
    {
      Departamento: 'Áncash',
      Provincia: 'Bolognesi',
      Distrito: 'San Miguel de Corpanqui',
    },
    { Departamento: 'Áncash', Provincia: 'Bolognesi', Distrito: 'Ticllos' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Carhuaz' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Acopampa' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Amashca' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Anta' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Ataquero' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Marcara' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Pariahuanca' },
    {
      Departamento: 'Áncash',
      Provincia: 'Carhuaz',
      Distrito: 'San Miguel de Aco',
    },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Shilla' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Tinco' },
    { Departamento: 'Áncash', Provincia: 'Carhuaz', Distrito: 'Yungar' },
    {
      Departamento: 'Áncash',
      Provincia: 'Carlos Fermín Fitzcarrald',
      Distrito: 'San Luis',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Carlos Fermín Fitzcarrald',
      Distrito: 'San Nicolás',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Carlos Fermín Fitzcarrald',
      Distrito: 'Yauya',
    },
    { Departamento: 'Áncash', Provincia: 'Casma', Distrito: 'Casma' },
    {
      Departamento: 'Áncash',
      Provincia: 'Casma',
      Distrito: 'Buena Vista Alta',
    },
    { Departamento: 'Áncash', Provincia: 'Casma', Distrito: 'Comandante Noel' },
    { Departamento: 'Áncash', Provincia: 'Casma', Distrito: 'Yautan' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Corongo' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Aco' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Bambas' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Cusca' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'La Pampa' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Yanac' },
    { Departamento: 'Áncash', Provincia: 'Corongo', Distrito: 'Yupan' },

    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Huari' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Anra' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Cajay' },
    {
      Departamento: 'Áncash',
      Provincia: 'Huari',
      Distrito: 'Chavin de Huantar',
    },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Huacachi' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Huacchis' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Huachis' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Huantar' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Masin' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Paucas' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Ponto' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Rahuapampa' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Rapayan' },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'San Marcos' },
    {
      Departamento: 'Áncash',
      Provincia: 'Huari',
      Distrito: 'San Pedro de Chana',
    },
    { Departamento: 'Áncash', Provincia: 'Huari', Distrito: 'Uco' },
    { Departamento: 'Áncash', Provincia: 'Huarmey', Distrito: 'Huarmey' },
    { Departamento: 'Áncash', Provincia: 'Huarmey', Distrito: 'Cochapeti' },
    { Departamento: 'Áncash', Provincia: 'Huarmey', Distrito: 'Culebras' },
    { Departamento: 'Áncash', Provincia: 'Huarmey', Distrito: 'Huayan' },
    { Departamento: 'Áncash', Provincia: 'Huarmey', Distrito: 'Malvas' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Caraz' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Huallanca' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Huata' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Huaylas' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Mato' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Pamparomas' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Pueblo Libre' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Santa Cruz' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Santo Toribio' },
    { Departamento: 'Áncash', Provincia: 'Huaylas', Distrito: 'Yuracmarca' },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Piscobamba',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Casca',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Eleazar Guzmán Barron',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Fidel Olivas Escudero',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Llama',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Llumpa',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Lucma',
    },
    {
      Departamento: 'Áncash',
      Provincia: 'Mariscal Luzuriaga',
      Distrito: 'Musga',
    },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Ocros' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Acas' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Cajamarquilla' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Carhuapampa' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Cochas' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Congas' },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'Llipa' },
    {
      Departamento: 'Áncash',
      Provincia: 'Ocros',
      Distrito: 'San Cristóbal de Rajan',
    },
    { Departamento: 'Áncash', Provincia: 'Ocros', Distrito: 'San Pedro' },
    {
      Departamento: 'Áncash',
      Provincia: 'Ocros',
      Distrito: 'Santiago de Chilcas',
    },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Cabana' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Bolognesi' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Conchucos' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Huacaschuque' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Huandoval' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Lacabamba' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Llapo' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Pallasca' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Pampas' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Santa Rosa' },
    { Departamento: 'Áncash', Provincia: 'Pallasca', Distrito: 'Tauca' },
    { Departamento: 'Áncash', Provincia: 'Pomabamba', Distrito: 'Pomabamba' },
    { Departamento: 'Áncash', Provincia: 'Pomabamba', Distrito: 'Huayllan' },
    { Departamento: 'Áncash', Provincia: 'Pomabamba', Distrito: 'Parobamba' },
    { Departamento: 'Áncash', Provincia: 'Pomabamba', Distrito: 'Quinuabamba' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Recuay' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Catac' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Cotaparaco' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Huayllapampa' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Llacllin' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Marca' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Pampas Chico' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Pararin' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Tapacocha' },
    { Departamento: 'Áncash', Provincia: 'Recuay', Distrito: 'Ticapampa' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Chimbote' },
    {
      Departamento: 'Áncash',
      Provincia: 'Santa',
      Distrito: 'Cáceres del Perú',
    },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Coishco' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Macate' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Moro' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Nepeña' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Samanco' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Santa' },
    { Departamento: 'Áncash', Provincia: 'Santa', Distrito: 'Nuevo Chimbote' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Sihuas' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Acobamba' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Alfonso Ugarte' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Cashapampa' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Chingalpo' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Huayllabamba' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Quiches' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Ragash' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'San Juan' },
    { Departamento: 'Áncash', Provincia: 'Sihuas', Distrito: 'Sicsibamba' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Yungay' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Cascapara' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Mancos' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Matacoto' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Quillo' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Ranrahirca' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Shupluy' },
    { Departamento: 'Áncash', Provincia: 'Yungay', Distrito: 'Yanama' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Abancay' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Chacoche' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Circa' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Curahuasi' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Huanipaca' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Lambrama' },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Pichirhua' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Abancay',
      Distrito: 'San Pedro de Cachora',
    },
    { Departamento: 'Apurímac', Provincia: 'Abancay', Distrito: 'Tamburco' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Andahuaylas',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Andarapa',
    },
    { Departamento: 'Apurímac', Provincia: 'Andahuaylas', Distrito: 'Chiara' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Huancarama',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Huancaray',
    },
    { Departamento: 'Apurímac', Provincia: 'Andahuaylas', Distrito: 'Huayana' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Kishuara',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Pacobamba',
    },
    { Departamento: 'Apurímac', Provincia: 'Andahuaylas', Distrito: 'Pacucha' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Pampachiri',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Pomacocha',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'San Antonio de Cachi',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'San Jerónimo',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'San Miguel de Chaccrampa',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Santa María de Chicmo',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Talavera',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Tumay Huaraca',
    },
    { Departamento: 'Apurímac', Provincia: 'Andahuaylas', Distrito: 'Turpo' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'Kaquiabamba',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Andahuaylas',
      Distrito: 'José María Arguedas',
    },
    { Departamento: 'Apurímac', Provincia: 'Antabamba', Distrito: 'Antabamba' },
    { Departamento: 'Apurímac', Provincia: 'Antabamba', Distrito: 'El Oro' },
    { Departamento: 'Apurímac', Provincia: 'Antabamba', Distrito: 'Huaquirca' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Antabamba',
      Distrito: 'Juan Espinoza Medrano',
    },
    { Departamento: 'Apurímac', Provincia: 'Antabamba', Distrito: 'Oropesa' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Antabamba',
      Distrito: 'Pachaconas',
    },
    { Departamento: 'Apurímac', Provincia: 'Antabamba', Distrito: 'Sabaino' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Chalhuanca' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Capaya' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Caraybamba' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Chapimarca' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Colcabamba' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Cotaruse' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Ihuayllo' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Aymaraes',
      Distrito: 'Justo Apu Sahuaraura',
    },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Lucre' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Pocohuanca' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Aymaraes',
      Distrito: 'San Juan de Chacña',
    },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Sañayca' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Soraya' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Tapairihua' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Tintay' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Toraya' },
    { Departamento: 'Apurímac', Provincia: 'Aymaraes', Distrito: 'Yanaca' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Cotabambas',
      Distrito: 'Tambobamba',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Cotabambas',
      Distrito: 'Cotabambas',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Cotabambas',
      Distrito: 'Coyllurqui',
    },
    { Departamento: 'Apurímac', Provincia: 'Cotabambas', Distrito: 'Haquira' },
    { Departamento: 'Apurímac', Provincia: 'Cotabambas', Distrito: 'Mara' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Cotabambas',
      Distrito: 'Challhuahuacho',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Chincheros',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Anco_Huallo',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Cocharcas',
    },
    { Departamento: 'Apurímac', Provincia: 'Chincheros', Distrito: 'Huaccana' },
    { Departamento: 'Apurímac', Provincia: 'Chincheros', Distrito: 'Ocobamba' },
    { Departamento: 'Apurímac', Provincia: 'Chincheros', Distrito: 'Ongoy' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Uranmarca',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Ranracancha',
    },
    { Departamento: 'Apurímac', Provincia: 'Chincheros', Distrito: 'Rocchacc' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'El Porvenir',
    },
    {
      Departamento: 'Apurímac',
      Provincia: 'Chincheros',
      Distrito: 'Los Chankas',
    },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Chuquibambilla' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Curpahuasi' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Gamarra' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Huayllati' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Mamara' },
    {
      Departamento: 'Apurímac',
      Provincia: 'Grau',
      Distrito: 'Micaela Bastidas',
    },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Pataypampa' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Progreso' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'San Antonio' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Santa Rosa' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Turpay' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Vilcabamba' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Virundo' },
    { Departamento: 'Apurímac', Provincia: 'Grau', Distrito: 'Curasco' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Arequipa' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Alto Selva Alegre',
    },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Cayma' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Cerro Colorado',
    },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Characato' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Chiguata' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Jacobo Hunter',
    },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'La Joya' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Mariano Melgar',
    },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Miraflores' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Mollebaya' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Paucarpata' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Pocsi' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Polobaya' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Quequeña' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Sabandia' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Sachaca' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'San Juan de Siguas',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'San Juan de Tarucani',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Santa Isabel de Siguas',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'Santa Rita de Siguas',
    },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Socabaya' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Tiabaya' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Uchumayo' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Vitor' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Yanahuara' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Yarabamba' },
    { Departamento: 'Arequipa', Provincia: 'Arequipa', Distrito: 'Yura' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Arequipa',
      Distrito: 'José Luis Bustamante Y Rivero',
    },
    { Departamento: 'Arequipa', Provincia: 'Camaná', Distrito: 'Camaná' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Camaná',
      Distrito: 'José María Quimper',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Camaná',
      Distrito: 'Mariano Nicolás Valcárcel',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Camaná',
      Distrito: 'Mariscal Cáceres',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Camaná',
      Distrito: 'Nicolás de Pierola',
    },
    { Departamento: 'Arequipa', Provincia: 'Camaná', Distrito: 'Ocoña' },
    { Departamento: 'Arequipa', Provincia: 'Camaná', Distrito: 'Quilca' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Camaná',
      Distrito: 'Samuel Pastor',
    },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Caravelí' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Acarí' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Atico' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Atiquipa' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Caravelí',
      Distrito: 'Bella Unión',
    },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Cahuacho' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Chala' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Chaparra' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Huanuhuanu' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Jaqui' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Lomas' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Quicacha' },
    { Departamento: 'Arequipa', Provincia: 'Caravelí', Distrito: 'Yauca' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Aplao' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Andagua' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Ayo' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Chachas' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Castilla',
      Distrito: 'Chilcaymarca',
    },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Choco' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Huancarqui' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Machaguay' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Orcopampa' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Pampacolca' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Tipan' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Uñon' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Uraca' },
    { Departamento: 'Arequipa', Provincia: 'Castilla', Distrito: 'Viraco' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Chivay' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Achoma' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Caylloma',
      Distrito: 'Cabanaconde',
    },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Callalli' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Caylloma' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Coporaque' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Huambo' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Huanca' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Ichupampa' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Lari' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Lluta' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Maca' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Madrigal' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Caylloma',
      Distrito: 'San Antonio de Chuca',
    },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Sibayo' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Tapay' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Tisco' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Tuti' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Yanque' },
    { Departamento: 'Arequipa', Provincia: 'Caylloma', Distrito: 'Majes' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Condesuyos',
      Distrito: 'Chuquibamba',
    },
    { Departamento: 'Arequipa', Provincia: 'Condesuyos', Distrito: 'Andaray' },
    { Departamento: 'Arequipa', Provincia: 'Condesuyos', Distrito: 'Cayarani' },
    { Departamento: 'Arequipa', Provincia: 'Condesuyos', Distrito: 'Chichas' },
    { Departamento: 'Arequipa', Provincia: 'Condesuyos', Distrito: 'Iray' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Condesuyos',
      Distrito: 'Río Grande',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Condesuyos',
      Distrito: 'Salamanca',
    },
    {
      Departamento: 'Arequipa',
      Provincia: 'Condesuyos',
      Distrito: 'Yanaquihua',
    },
    { Departamento: 'Arequipa', Provincia: 'Islay', Distrito: 'Mollendo' },
    { Departamento: 'Arequipa', Provincia: 'Islay', Distrito: 'Cocachacra' },
    { Departamento: 'Arequipa', Provincia: 'Islay', Distrito: 'Dean Valdivia' },
    { Departamento: 'Arequipa', Provincia: 'Islay', Distrito: 'Islay' },
    { Departamento: 'Arequipa', Provincia: 'Islay', Distrito: 'Mejia' },
    {
      Departamento: 'Arequipa',
      Provincia: 'Islay',
      Distrito: 'Punta de Bombón',
    },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Cotahuasi' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Alca' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Charcana' },
    {
      Departamento: 'Arequipa',
      Provincia: 'La Uniòn',
      Distrito: 'Huaynacotas',
    },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Pampamarca' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Puyca' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Quechualla' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Sayla' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Tauria' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Tomepampa' },
    { Departamento: 'Arequipa', Provincia: 'La Uniòn', Distrito: 'Toro' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Ayacucho' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Acocro' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'Acos Vinchos',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'Carmen Alto',
    },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Chiara' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Ocros' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Pacaycasa' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Quinua' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'San José de Ticllas',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'San Juan Bautista',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'Santiago de Pischa',
    },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Socos' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Tambillo' },
    { Departamento: 'Ayacucho', Provincia: 'Huamanga', Distrito: 'Vinchos' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'Jesús Nazareno',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huamanga',
      Distrito: 'Andrés Avelino Cáceres Dorregaray',
    },
    { Departamento: 'Ayacucho', Provincia: 'Cangallo', Distrito: 'Cangallo' },
    { Departamento: 'Ayacucho', Provincia: 'Cangallo', Distrito: 'Chuschi' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Cangallo',
      Distrito: 'Los Morochucos',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Cangallo',
      Distrito: 'María Parado de Bellido',
    },
    { Departamento: 'Ayacucho', Provincia: 'Cangallo', Distrito: 'Paras' },
    { Departamento: 'Ayacucho', Provincia: 'Cangallo', Distrito: 'Totos' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huanca Sancos',
      Distrito: 'Sancos',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huanca Sancos',
      Distrito: 'Carapo',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huanca Sancos',
      Distrito: 'Sacsamarca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Huanca Sancos',
      Distrito: 'Santiago de Lucanamarca',
    },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Huanta' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Ayahuanco' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Huamanguilla' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Iguain' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Luricocha' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Santillana' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Sivia' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Llochegua' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Canayre' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Uchuraccay' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Pucacolpa' },
    { Departamento: 'Ayacucho', Provincia: 'Huanta', Distrito: 'Chaca' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'San Miguel' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Anco' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Ayna' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Chilcas' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Chungui' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'La Mar',
      Distrito: 'Luis Carranza',
    },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Santa Rosa' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Tambo' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Samugari' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Anchihuay' },
    { Departamento: 'Ayacucho', Provincia: 'La Mar', Distrito: 'Oronccoy' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Puquio' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Aucara' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Cabana' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Lucanas',
      Distrito: 'Carmen Salcedo',
    },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Chaviña' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Chipao' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Huac-Huas' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Laramate' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Lucanas',
      Distrito: 'Leoncio Prado',
    },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Llauta' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Lucanas' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Ocaña' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Otoca' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Saisa' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Lucanas',
      Distrito: 'San Cristóbal',
    },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'San Juan' },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'San Pedro' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Lucanas',
      Distrito: 'San Pedro de Palco',
    },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Sancos' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Lucanas',
      Distrito: 'Santa Ana de Huaycahuacho',
    },
    { Departamento: 'Ayacucho', Provincia: 'Lucanas', Distrito: 'Santa Lucia' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'Coracora',
    },
    { Departamento: 'Ayacucho', Provincia: 'Parinacochas', Distrito: 'Chumpi' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'Coronel Castañeda',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'Pacapausa',
    },
    { Departamento: 'Ayacucho', Provincia: 'Parinacochas', Distrito: 'Pullo' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'Puyusca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'San Francisco de Ravacayco',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Parinacochas',
      Distrito: 'Upahuacho',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Pausa',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Colta',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Corculla',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Lampa',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Marcabamba',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Oyolo',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Pararca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'San Javier de Alpabamba',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'San José de Ushua',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Pàucar del Sara Sara',
      Distrito: 'Sara Sara',
    },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Querobamba' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Belén' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Chalcos' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Chilcayoc' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Huacaña' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Morcolla' },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Paico' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Sucre',
      Distrito: 'San Pedro de Larcay',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Sucre',
      Distrito: 'San Salvador de Quije',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Sucre',
      Distrito: 'Santiago de Paucaray',
    },
    { Departamento: 'Ayacucho', Provincia: 'Sucre', Distrito: 'Soras' },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Huancapi',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Alcamenca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Apongo',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Asquipata',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Canaria',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Cayara',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Colca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Huamanquiquia',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Huancaraylla',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Hualla',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Sarhua',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Víctor Fajardo',
      Distrito: 'Vilcanchos',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Vilcas Huaman',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Accomarca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Carhuanca',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Concepción',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Huambalpa',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Independencia',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Saurama',
    },
    {
      Departamento: 'Ayacucho',
      Provincia: 'Vilcas Huamán',
      Distrito: 'Vischongo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajamarca',
      Distrito: 'Cajamarca',
    },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Asunción' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Chetilla' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Cospan' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Encañada' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Jesús' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajamarca',
      Distrito: 'Llacanora',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajamarca',
      Distrito: 'Los Baños del Inca',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajamarca',
      Distrito: 'Magdalena',
    },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Matara' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'Namora' },
    { Departamento: 'Cajamarca', Provincia: 'Cajamarca', Distrito: 'San Juan' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajabamba',
      Distrito: 'Cajabamba',
    },
    { Departamento: 'Cajamarca', Provincia: 'Cajabamba', Distrito: 'Cachachi' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajabamba',
      Distrito: 'Condebamba',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cajabamba',
      Distrito: 'Sitacocha',
    },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Celendín' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Chumuch' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Cortegana' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Huasmin' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Celendín',
      Distrito: 'Jorge Chávez',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Celendín',
      Distrito: 'José Gálvez',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Celendín',
      Distrito: 'Miguel Iglesias',
    },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Oxamarca' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Sorochuco' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Sucre' },
    { Departamento: 'Cajamarca', Provincia: 'Celendín', Distrito: 'Utco' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Celendín',
      Distrito: 'La Libertad de Pallan',
    },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Chota' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Anguia' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Chadin' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Chiguirip' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Chimban' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Choropampa' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Cochabamba' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Conchan' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Huambos' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Lajas' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Llama' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Miracosta' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Paccha' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Pion' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Querocoto' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Chota',
      Distrito: 'San Juan de Licupis',
    },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Tacabamba' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Tocmoche' },
    { Departamento: 'Cajamarca', Provincia: 'Chota', Distrito: 'Chalamarca' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Contumazá',
      Distrito: 'Contumaza',
    },
    { Departamento: 'Cajamarca', Provincia: 'Contumazá', Distrito: 'Chilete' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Contumazá',
      Distrito: 'Cupisnique',
    },
    { Departamento: 'Cajamarca', Provincia: 'Contumazá', Distrito: 'Guzmango' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Contumazá',
      Distrito: 'San Benito',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Contumazá',
      Distrito: 'Santa Cruz de Toledo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Contumazá',
      Distrito: 'Tantarica',
    },
    { Departamento: 'Cajamarca', Provincia: 'Contumazá', Distrito: 'Yonan' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Cutervo' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Callayuc' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Choros' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Cujillo' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'La Ramada' },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Pimpingos' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'Querocotillo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'San Andrés de Cutervo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'San Juan de Cutervo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'San Luis de Lucma',
    },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Santa Cruz' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'Santo Domingo de la Capilla',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'Santo Tomas',
    },
    { Departamento: 'Cajamarca', Provincia: 'Cutervo', Distrito: 'Socota' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Cutervo',
      Distrito: 'Toribio Casanova',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Hualgayoc',
      Distrito: 'Bambamarca',
    },
    { Departamento: 'Cajamarca', Provincia: 'Hualgayoc', Distrito: 'Chugur' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Hualgayoc',
      Distrito: 'Hualgayoc',
    },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Jaén' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Bellavista' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Chontali' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Colasay' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Huabal' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Las Pirias' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Pomahuaca' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Pucara' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Sallique' },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'San Felipe' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Jaén',
      Distrito: 'San José del Alto',
    },
    { Departamento: 'Cajamarca', Provincia: 'Jaén', Distrito: 'Santa Rosa' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'San Ignacio',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'Chirinos',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'Huarango',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'La Coipa',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'Namballe',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'San José de Lourdes',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Ignacio',
      Distrito: 'Tabaconas',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Marcos',
      Distrito: 'Pedro Gálvez',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Marcos', Distrito: 'Chancay' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Marcos',
      Distrito: 'Eduardo Villanueva',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Marcos',
      Distrito: 'Gregorio Pita',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Marcos', Distrito: 'Ichocan' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Marcos',
      Distrito: 'José Manuel Quiroz',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Marcos',
      Distrito: 'José Sabogal',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'San Miguel',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Bolívar' },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Calquis' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'Catilluc',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'El Prado',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'La Florida',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Llapa' },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Nanchoc' },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Niepos' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'San Gregorio',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'San Silvestre de Cochan',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Miguel', Distrito: 'Tongod' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Miguel',
      Distrito: 'Unión Agua Blanca',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Pablo',
      Distrito: 'San Pablo',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'San Pablo',
      Distrito: 'San Bernardino',
    },
    { Departamento: 'Cajamarca', Provincia: 'San Pablo', Distrito: 'San Luis' },
    { Departamento: 'Cajamarca', Provincia: 'San Pablo', Distrito: 'Tumbaden' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Santa Cruz',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Andabamba',
    },
    { Departamento: 'Cajamarca', Provincia: 'Santa Cruz', Distrito: 'Catache' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Chancaybaños',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'La Esperanza',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Ninabamba',
    },
    { Departamento: 'Cajamarca', Provincia: 'Santa Cruz', Distrito: 'Pulan' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Saucepampa',
    },
    { Departamento: 'Cajamarca', Provincia: 'Santa Cruz', Distrito: 'Sexi' },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Uticyacu',
    },
    {
      Departamento: 'Cajamarca',
      Provincia: 'Santa Cruz',
      Distrito: 'Yauyucan',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'Callao',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'Bellavista',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'Carmen de la Legua Reynoso',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'La Perla',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'La Punta',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'Ventanilla',
    },
    {
      Departamento: 'Callao',
      Provincia: 'Prov. Const. del Callao',
      Distrito: 'Mi Perú',
    },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Cusco' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Ccorca' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Poroy' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'San Jerónimo' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'San Sebastian' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Santiago' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Saylla' },
    { Departamento: 'Cusco', Provincia: 'Cusco', Distrito: 'Wanchaq' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Acomayo' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Acopia' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Acos' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Mosoc Llacta' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Pomacanchi' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Rondocan' },
    { Departamento: 'Cusco', Provincia: 'Acomayo', Distrito: 'Sangarara' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Anta' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Ancahuasi' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Cachimayo' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Chinchaypujio' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Huarocondo' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Limatambo' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Mollepata' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Pucyura' },
    { Departamento: 'Cusco', Provincia: 'Anta', Distrito: 'Zurite' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Calca' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Coya' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Lamay' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Lares' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Pisac' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'San Salvador' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Taray' },
    { Departamento: 'Cusco', Provincia: 'Calca', Distrito: 'Yanatile' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Yanaoca' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Checca' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Kunturkanki' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Langui' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Layo' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Pampamarca' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Quehue' },
    { Departamento: 'Cusco', Provincia: 'Canas', Distrito: 'Tupac Amaru' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Sicuani' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Checacupe' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Combapata' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Marangani' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Pitumarca' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'San Pablo' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'San Pedro' },
    { Departamento: 'Cusco', Provincia: 'Canchis', Distrito: 'Tinta' },
    {
      Departamento: 'Cusco',
      Provincia: 'Chumbivilcas',
      Distrito: 'Santo Tomas',
    },
    {
      Departamento: 'Cusco',
      Provincia: 'Chumbivilcas',
      Distrito: 'Capacmarca',
    },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas', Distrito: 'Chamaca' },
    {
      Departamento: 'Cusco',
      Provincia: 'Chumbivilcas',
      Distrito: 'Colquemarca',
    },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas', Distrito: 'Livitaca' },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas', Distrito: 'Llusco' },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas', Distrito: 'Quiñota' },
    { Departamento: 'Cusco', Provincia: 'Chumbivilcas', Distrito: 'Velille' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Espinar' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Condoroma' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Coporaque' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Ocoruro' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Pallpata' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Pichigua' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Suyckutambo' },
    { Departamento: 'Cusco', Provincia: 'Espinar', Distrito: 'Alto Pichigua' },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Santa Ana',
    },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Echarate' },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Huayopata',
    },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Maranura' },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Ocobamba' },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Quellouno',
    },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Kimbiri' },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Santa Teresa',
    },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Vilcabamba',
    },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Pichari' },
    { Departamento: 'Cusco', Provincia: 'La Convención', Distrito: 'Inkawasi' },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Villa Virgen',
    },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Villa Kintiarina',
    },
    {
      Departamento: 'Cusco',
      Provincia: 'La Convención',
      Distrito: 'Megantoni',
    },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Paruro' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Accha' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Ccapi' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Colcha' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Huanoquite' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Omacha' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Paccaritambo' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Pillpinto' },
    { Departamento: 'Cusco', Provincia: 'Paruro', Distrito: 'Yaurisque' },
    {
      Departamento: 'Cusco',
      Provincia: 'Paucartambo',
      Distrito: 'Paucartambo',
    },
    { Departamento: 'Cusco', Provincia: 'Paucartambo', Distrito: 'Caicay' },
    {
      Departamento: 'Cusco',
      Provincia: 'Paucartambo',
      Distrito: 'Challabamba',
    },
    { Departamento: 'Cusco', Provincia: 'Paucartambo', Distrito: 'Colquepata' },
    { Departamento: 'Cusco', Provincia: 'Paucartambo', Distrito: 'Huancarani' },
    { Departamento: 'Cusco', Provincia: 'Paucartambo', Distrito: 'Kosñipata' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Urcos' },
    {
      Departamento: 'Cusco',
      Provincia: 'Quispicanchi',
      Distrito: 'Andahuaylillas',
    },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Camanti' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Ccarhuayo' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Ccatca' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Cusipata' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Huaro' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Lucre' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Marcapata' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Ocongate' },
    { Departamento: 'Cusco', Provincia: 'Quispicanchi', Distrito: 'Oropesa' },
    {
      Departamento: 'Cusco',
      Provincia: 'Quispicanchi',
      Distrito: 'Quiquijana',
    },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Urubamba' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Chinchero' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Huayllabamba' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Machupicchu' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Maras' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Ollantaytambo' },
    { Departamento: 'Cusco', Provincia: 'Urubamba', Distrito: 'Yucay' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Huancavelica',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Acobambilla',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Acoria',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Conayca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Cuenca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Huachocolpa',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Huayllahuara',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Izcuchaca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Laria',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Manta',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Mariscal Cáceres',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Moya',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Nuevo Occoro',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Palca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Pilchaca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Vilca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Yauli',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Ascensión',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huancavelica',
      Distrito: 'Huando',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Acobamba',
      Distrito: 'Acobamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Acobamba',
      Distrito: 'Andabamba',
    },
    { Departamento: 'Huancavelica', Provincia: 'Acobamba', Distrito: 'Anta' },
    { Departamento: 'Huancavelica', Provincia: 'Acobamba', Distrito: 'Caja' },
    { Departamento: 'Huancavelica', Provincia: 'Acobamba', Distrito: 'Marcas' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Acobamba',
      Distrito: 'Paucara',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Acobamba',
      Distrito: 'Pomacocha',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Acobamba',
      Distrito: 'Rosario',
    },
    { Departamento: 'Huancavelica', Provincia: 'Angaraes', Distrito: 'Lircay' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Anchonga',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Callanmarca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Ccochaccasa',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Chincho',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Congalla',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Huanca-Huanca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Huayllay Grande',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Julcamarca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'San Antonio de Antaparco',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Santo Tomas de Pata',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Angaraes',
      Distrito: 'Secclla',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Castrovirreyna',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Arma',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Aurahua',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Capillas',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Chupamarca',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Cocas',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Huachos',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Huamatambo',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Mollepampa',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'San Juan',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Santa Ana',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Tantara',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Castrovirreyna',
      Distrito: 'Ticrapo',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'Churcampa',
    },
    { Departamento: 'Huancavelica', Provincia: 'Churcampa', Distrito: 'Anco' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'Chinchihuasi',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'El Carmen',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'La Merced',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'Locroja',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'Paucarbamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'San Miguel de Mayocc',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'San Pedro de Coris',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Churcampa',
      Distrito: 'Pachamarca',
    },
    { Departamento: 'Huancavelica', Provincia: 'Churcampa', Distrito: 'Cosme' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Huaytara',
    },
    { Departamento: 'Huancavelica', Provincia: 'Huaytará', Distrito: 'Ayavi' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Córdova',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Huayacundo Arma',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Laramarca',
    },
    { Departamento: 'Huancavelica', Provincia: 'Huaytará', Distrito: 'Ocoyo' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Pilpichaca',
    },
    { Departamento: 'Huancavelica', Provincia: 'Huaytará', Distrito: 'Querco' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Quito-Arma',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'San Antonio de Cusicancha',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'San Francisco de Sangayaico',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'San Isidro',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Santiago de Chocorvos',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Santiago de Quirahuara',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Huaytará',
      Distrito: 'Santo Domingo de Capillas',
    },
    { Departamento: 'Huancavelica', Provincia: 'Huaytará', Distrito: 'Tambo' },
    { Departamento: 'Huancavelica', Provincia: 'Tayacaja', Distrito: 'Pampas' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Acostambo',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Acraquia',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Ahuaycha',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Colcabamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Daniel Hernández',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Huachocolpa',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Huaribamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Ñahuimpuquio',
    },
    { Departamento: 'Huancavelica', Provincia: 'Tayacaja', Distrito: 'Pazos' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Quishuar',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Salcabamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Salcahuasi',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'San Marcos de Rocchac',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Surcubamba',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Tintay Puncu',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Quichuas',
    },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Andaymarca',
    },
    { Departamento: 'Huancavelica', Provincia: 'Tayacaja', Distrito: 'Roble' },
    { Departamento: 'Huancavelica', Provincia: 'Tayacaja', Distrito: 'Pichos' },
    {
      Departamento: 'Huancavelica',
      Provincia: 'Tayacaja',
      Distrito: 'Santiago de Tucuma',
    },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Huanuco' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Amarilis' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Chinchao' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Churubamba' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Margos' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huánuco',
      Distrito: 'Quisqui (Kichki)',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huánuco',
      Distrito: 'San Francisco de Cayran',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huánuco',
      Distrito: 'San Pedro de Chaulan',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huánuco',
      Distrito: 'Santa María del Valle',
    },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Yarumayo' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Pillco Marca' },
    { Departamento: 'Huánuco', Provincia: 'Huánuco', Distrito: 'Yacus' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huánuco',
      Distrito: 'San Pablo de Pillao',
    },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Ambo' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Cayna' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Colpas' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Conchamarca' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Huacar' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'San Francisco' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'San Rafael' },
    { Departamento: 'Huánuco', Provincia: 'Ambo', Distrito: 'Tomay Kichwa' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'La Unión' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Chuquis' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Marías' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Pachas' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Quivilla' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Ripan' },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Shunqui' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Dos de Mayo',
      Distrito: 'Sillapata',
    },
    { Departamento: 'Huánuco', Provincia: 'Dos de Mayo', Distrito: 'Yanas' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huacaybamba',
      Distrito: 'Huacaybamba',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huacaybamba',
      Distrito: 'Canchabamba',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huacaybamba',
      Distrito: 'Cochabamba',
    },
    { Departamento: 'Huánuco', Provincia: 'Huacaybamba', Distrito: 'Pinra' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Llata' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Arancay' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huamalíes',
      Distrito: 'Chavín de Pariarca',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Huamalíes',
      Distrito: 'Jacas Grande',
    },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Jircan' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Miraflores' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Monzón' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Punchao' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Puños' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Singa' },
    { Departamento: 'Huánuco', Provincia: 'Huamalíes', Distrito: 'Tantamayo' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Rupa-Rupa',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Daniel Alomía Robles',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Hermílio Valdizan',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'José Crespo y Castillo',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Luyando',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Mariano Damaso Beraun',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Pucayacu',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Castillo Grande',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Pueblo Nuevo',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Leoncio Prado',
      Distrito: 'Santo Domingo de Anda',
    },
    { Departamento: 'Huánuco', Provincia: 'Marañón', Distrito: 'Huacrachuco' },
    { Departamento: 'Huánuco', Provincia: 'Marañón', Distrito: 'Cholon' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Marañón',
      Distrito: 'San Buenaventura',
    },
    { Departamento: 'Huánuco', Provincia: 'Marañón', Distrito: 'La Morada' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Marañón',
      Distrito: 'Santa Rosa de Alto Yanajanca',
    },
    { Departamento: 'Huánuco', Provincia: 'Pachitea', Distrito: 'Panao' },
    { Departamento: 'Huánuco', Provincia: 'Pachitea', Distrito: 'Chaglla' },
    { Departamento: 'Huánuco', Provincia: 'Pachitea', Distrito: 'Molino' },
    { Departamento: 'Huánuco', Provincia: 'Pachitea', Distrito: 'Umari' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Puerto Inca',
      Distrito: 'Puerto Inca',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Puerto Inca',
      Distrito: 'Codo del Pozuzo',
    },
    { Departamento: 'Huánuco', Provincia: 'Puerto Inca', Distrito: 'Honoria' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Puerto Inca',
      Distrito: 'Tournavista',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Puerto Inca',
      Distrito: 'Yuyapichis',
    },
    { Departamento: 'Huánuco', Provincia: 'Lauricocha', Distrito: 'Jesús' },
    { Departamento: 'Huánuco', Provincia: 'Lauricocha', Distrito: 'Baños' },
    { Departamento: 'Huánuco', Provincia: 'Lauricocha', Distrito: 'Jivia' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Lauricocha',
      Distrito: 'Queropalca',
    },
    { Departamento: 'Huánuco', Provincia: 'Lauricocha', Distrito: 'Rondos' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Lauricocha',
      Distrito: 'San Francisco de Asís',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Lauricocha',
      Distrito: 'San Miguel de Cauri',
    },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Chavinillo' },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Cahuac' },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Chacabamba' },
    {
      Departamento: 'Huánuco',
      Provincia: 'Yarowilca',
      Distrito: 'Aparicio Pomares',
    },
    {
      Departamento: 'Huánuco',
      Provincia: 'Yarowilca',
      Distrito: 'Jacas Chico',
    },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Obas' },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Pampamarca' },
    { Departamento: 'Huánuco', Provincia: 'Yarowilca', Distrito: 'Choras' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Ica' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'La Tinguiña' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Los Aquijes' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Ocucaje' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Pachacutec' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Parcona' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Pueblo Nuevo' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Salas' },
    {
      Departamento: 'Ica',
      Provincia: 'Ica',
      Distrito: 'San José de Los Molinos',
    },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'San Juan Bautista' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Santiago' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Subtanjalla' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Tate' },
    { Departamento: 'Ica', Provincia: 'Ica', Distrito: 'Yauca del Rosario' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Chincha Alta' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Alto Laran' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Chavin' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Chincha Baja' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'El Carmen' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Grocio Prado' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Pueblo Nuevo' },
    {
      Departamento: 'Ica',
      Provincia: 'Chincha',
      Distrito: 'San Juan de Yanac',
    },
    {
      Departamento: 'Ica',
      Provincia: 'Chincha',
      Distrito: 'San Pedro de Huacarpana',
    },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Sunampe' },
    { Departamento: 'Ica', Provincia: 'Chincha', Distrito: 'Tambo de Mora' },
    { Departamento: 'Ica', Provincia: 'Nasca', Distrito: 'Nasca' },
    { Departamento: 'Ica', Provincia: 'Nasca', Distrito: 'Changuillo' },
    { Departamento: 'Ica', Provincia: 'Nasca', Distrito: 'El Ingenio' },
    { Departamento: 'Ica', Provincia: 'Nasca', Distrito: 'Marcona' },
    { Departamento: 'Ica', Provincia: 'Nasca', Distrito: 'Vista Alegre' },
    { Departamento: 'Ica', Provincia: 'Palpa', Distrito: 'Palpa' },
    { Departamento: 'Ica', Provincia: 'Palpa', Distrito: 'Llipata' },
    { Departamento: 'Ica', Provincia: 'Palpa', Distrito: 'Río Grande' },
    { Departamento: 'Ica', Provincia: 'Palpa', Distrito: 'Santa Cruz' },
    { Departamento: 'Ica', Provincia: 'Palpa', Distrito: 'Tibillo' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Pisco' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Huancano' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Humay' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Independencia' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Paracas' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'San Andrés' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'San Clemente' },
    { Departamento: 'Ica', Provincia: 'Pisco', Distrito: 'Tupac Amaru Inca' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Huancayo' },
    {
      Departamento: 'Junín',
      Provincia: 'Huancayo',
      Distrito: 'Carhuacallanga',
    },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Chacapampa' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Chicche' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Chilca' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Chongos Alto' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Chupuro' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Colca' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Cullhuas' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'El Tambo' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Huacrapuquio' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Hualhuas' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Huancan' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Huasicancha' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Huayucachi' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Ingenio' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Pariahuanca' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Pilcomayo' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Pucara' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Quichuay' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Quilcas' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'San Agustín' },
    {
      Departamento: 'Junín',
      Provincia: 'Huancayo',
      Distrito: 'San Jerónimo de Tunan',
    },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Saño' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Sapallanga' },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Sicaya' },
    {
      Departamento: 'Junín',
      Provincia: 'Huancayo',
      Distrito: 'Santo Domingo de Acobamba',
    },
    { Departamento: 'Junín', Provincia: 'Huancayo', Distrito: 'Viques' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Concepción' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Aco' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Andamarca' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Chambara' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Cochas' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Comas' },
    {
      Departamento: 'Junín',
      Provincia: 'Concepción',
      Distrito: 'Heroínas Toledo',
    },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Manzanares' },
    {
      Departamento: 'Junín',
      Provincia: 'Concepción',
      Distrito: 'Mariscal Castilla',
    },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Matahuasi' },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Mito' },
    {
      Departamento: 'Junín',
      Provincia: 'Concepción',
      Distrito: 'Nueve de Julio',
    },
    { Departamento: 'Junín', Provincia: 'Concepción', Distrito: 'Orcotuna' },
    {
      Departamento: 'Junín',
      Provincia: 'Concepción',
      Distrito: 'San José de Quero',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Concepción',
      Distrito: 'Santa Rosa de Ocopa',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Chanchamayo',
      Distrito: 'Chanchamayo',
    },
    { Departamento: 'Junín', Provincia: 'Chanchamayo', Distrito: 'Perene' },
    { Departamento: 'Junín', Provincia: 'Chanchamayo', Distrito: 'Pichanaqui' },
    {
      Departamento: 'Junín',
      Provincia: 'Chanchamayo',
      Distrito: 'San Luis de Shuaro',
    },
    { Departamento: 'Junín', Provincia: 'Chanchamayo', Distrito: 'San Ramón' },
    { Departamento: 'Junín', Provincia: 'Chanchamayo', Distrito: 'Vitoc' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Jauja' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Acolla' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Apata' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Ataura' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Canchayllo' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Curicaca' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'El Mantaro' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Huamali' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Huaripampa' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Huertas' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Janjaillo' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Julcán' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Leonor Ordóñez' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Llocllapampa' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Marco' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Masma' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Masma Chicche' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Molinos' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Monobamba' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Muqui' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Muquiyauyo' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Paca' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Paccha' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Pancan' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Parco' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Pomacancha' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Ricran' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'San Lorenzo' },
    {
      Departamento: 'Junín',
      Provincia: 'Jauja',
      Distrito: 'San Pedro de Chunan',
    },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Sausa' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Sincos' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Tunan Marca' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Yauli' },
    { Departamento: 'Junín', Provincia: 'Jauja', Distrito: 'Yauyos' },
    { Departamento: 'Junín', Provincia: 'Junín', Distrito: 'Junin' },
    { Departamento: 'Junín', Provincia: 'Junín', Distrito: 'Carhuamayo' },
    { Departamento: 'Junín', Provincia: 'Junín', Distrito: 'Ondores' },
    { Departamento: 'Junín', Provincia: 'Junín', Distrito: 'Ulcumayo' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Satipo' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Coviriali' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Llaylla' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Mazamari' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Pampa Hermosa' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Pangoa' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Río Negro' },
    { Departamento: 'Junín', Provincia: 'Satipo', Distrito: 'Río Tambo' },
    {
      Departamento: 'Junín',
      Provincia: 'Satipo',
      Distrito: 'Vizcatan del Ene',
    },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Tarma' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Acobamba' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Huaricolca' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Huasahuasi' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'La Unión' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Palca' },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Palcamayo' },
    {
      Departamento: 'Junín',
      Provincia: 'Tarma',
      Distrito: 'San Pedro de Cajas',
    },
    { Departamento: 'Junín', Provincia: 'Tarma', Distrito: 'Tapo' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'La Oroya' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Chacapalpa' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Huay-Huay' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Marcapomacocha' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Morococha' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Paccha' },
    {
      Departamento: 'Junín',
      Provincia: 'Yauli',
      Distrito: 'Santa Bárbara de Carhuacayan',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Yauli',
      Distrito: 'Santa Rosa de Sacco',
    },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Suitucancha' },
    { Departamento: 'Junín', Provincia: 'Yauli', Distrito: 'Yauli' },
    { Departamento: 'Junín', Provincia: 'Chupaca', Distrito: 'Chupaca' },
    { Departamento: 'Junín', Provincia: 'Chupaca', Distrito: 'Ahuac' },
    { Departamento: 'Junín', Provincia: 'Chupaca', Distrito: 'Chongos Bajo' },
    { Departamento: 'Junín', Provincia: 'Chupaca', Distrito: 'Huachac' },
    {
      Departamento: 'Junín',
      Provincia: 'Chupaca',
      Distrito: 'Huamancaca Chico',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Chupaca',
      Distrito: 'San Juan de Iscos',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Chupaca',
      Distrito: 'San Juan de Jarpa',
    },
    {
      Departamento: 'Junín',
      Provincia: 'Chupaca',
      Distrito: 'Tres de Diciembre',
    },
    { Departamento: 'Junín', Provincia: 'Chupaca', Distrito: 'Yanacancha' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'Trujillo',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'El Porvenir',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'Florencia de Mora',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'Huanchaco',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'La Esperanza',
    },
    { Departamento: 'La Libertad', Provincia: 'Trujillo', Distrito: 'Laredo' },
    { Departamento: 'La Libertad', Provincia: 'Trujillo', Distrito: 'Moche' },
    { Departamento: 'La Libertad', Provincia: 'Trujillo', Distrito: 'Poroto' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'Salaverry',
    },
    { Departamento: 'La Libertad', Provincia: 'Trujillo', Distrito: 'Simbal' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Trujillo',
      Distrito: 'Victor Larco Herrera',
    },
    { Departamento: 'La Libertad', Provincia: 'Ascope', Distrito: 'Ascope' },
    { Departamento: 'La Libertad', Provincia: 'Ascope', Distrito: 'Chicama' },
    { Departamento: 'La Libertad', Provincia: 'Ascope', Distrito: 'Chocope' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Ascope',
      Distrito: 'Magdalena de Cao',
    },
    { Departamento: 'La Libertad', Provincia: 'Ascope', Distrito: 'Paijan' },
    { Departamento: 'La Libertad', Provincia: 'Ascope', Distrito: 'Rázuri' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Ascope',
      Distrito: 'Santiago de Cao',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Ascope',
      Distrito: 'Casa Grande',
    },
    { Departamento: 'La Libertad', Provincia: 'Bolívar', Distrito: 'Bolívar' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Bolívar',
      Distrito: 'Bambamarca',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Bolívar',
      Distrito: 'Condormarca',
    },
    { Departamento: 'La Libertad', Provincia: 'Bolívar', Distrito: 'Longotea' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Bolívar',
      Distrito: 'Uchumarca',
    },
    { Departamento: 'La Libertad', Provincia: 'Bolívar', Distrito: 'Ucuncha' },
    { Departamento: 'La Libertad', Provincia: 'Chepén', Distrito: 'Chepen' },
    { Departamento: 'La Libertad', Provincia: 'Chepén', Distrito: 'Pacanga' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Chepén',
      Distrito: 'Pueblo Nuevo',
    },
    { Departamento: 'La Libertad', Provincia: 'Julcán', Distrito: 'Julcan' },
    { Departamento: 'La Libertad', Provincia: 'Julcán', Distrito: 'Calamarca' },
    { Departamento: 'La Libertad', Provincia: 'Julcán', Distrito: 'Carabamba' },
    { Departamento: 'La Libertad', Provincia: 'Julcán', Distrito: 'Huaso' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Otuzco' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Otuzco',
      Distrito: 'Agallpampa',
    },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Charat' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Otuzco',
      Distrito: 'Huaranchal',
    },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'La Cuesta' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Mache' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Paranday' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Salpo' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Sinsicap' },
    { Departamento: 'La Libertad', Provincia: 'Otuzco', Distrito: 'Usquil' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pacasmayo',
      Distrito: 'San Pedro de Lloc',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pacasmayo',
      Distrito: 'Guadalupe',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pacasmayo',
      Distrito: 'Jequetepeque',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pacasmayo',
      Distrito: 'Pacasmayo',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pacasmayo',
      Distrito: 'San José',
    },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Tayabamba' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Buldibuyo' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Chillia' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pataz',
      Distrito: 'Huancaspata',
    },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Huaylillas' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Huayo' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Ongon' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Parcoy' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Pataz' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Pias' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Pataz',
      Distrito: 'Santiago de Challas',
    },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Taurija' },
    { Departamento: 'La Libertad', Provincia: 'Pataz', Distrito: 'Urpay' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Huamachuco',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Chugay',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Cochorco',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Curgos',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Marcabal',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Sanagoran',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Sarin',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Sánchez Carrión',
      Distrito: 'Sartimbamba',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Santiago de Chuco',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Angasmarca',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Cachicadan',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Mollebamba',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Mollepata',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Quiruvilca',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Santa Cruz de Chuca',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Santiago de Chuco',
      Distrito: 'Sitabamba',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Gran Chimú',
      Distrito: 'Cascas',
    },
    { Departamento: 'La Libertad', Provincia: 'Gran Chimú', Distrito: 'Lucma' },
    {
      Departamento: 'La Libertad',
      Provincia: 'Gran Chimú',
      Distrito: 'Marmot',
    },
    {
      Departamento: 'La Libertad',
      Provincia: 'Gran Chimú',
      Distrito: 'Sayapullo',
    },
    { Departamento: 'La Libertad', Provincia: 'Virú', Distrito: 'Viru' },
    { Departamento: 'La Libertad', Provincia: 'Virú', Distrito: 'Chao' },
    { Departamento: 'La Libertad', Provincia: 'Virú', Distrito: 'Guadalupito' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Chiclayo' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'Chongoyape',
    },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Eten' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'Eten Puerto',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'José Leonardo Ortiz',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'La Victoria',
    },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Lagunas' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Monsefu' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'Nueva Arica',
    },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Oyotun' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Picsi' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Pimentel' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Reque' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Chiclayo',
      Distrito: 'Santa Rosa',
    },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Saña' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Cayalti' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Patapo' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Pomalca' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Pucala' },
    { Departamento: 'Lambayeque', Provincia: 'Chiclayo', Distrito: 'Tuman' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Ferreñafe',
      Distrito: 'Ferreñafe',
    },
    { Departamento: 'Lambayeque', Provincia: 'Ferreñafe', Distrito: 'Cañaris' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Ferreñafe',
      Distrito: 'Incahuasi',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Ferreñafe',
      Distrito: 'Manuel Antonio Mesones Muro',
    },
    { Departamento: 'Lambayeque', Provincia: 'Ferreñafe', Distrito: 'Pitipo' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Ferreñafe',
      Distrito: 'Pueblo Nuevo',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'Lambayeque',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'Chochope',
    },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Illimo' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'Jayanca',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'Mochumi',
    },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'Morrope',
    },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Motupe' },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Olmos' },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Pacora' },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Salas' },
    {
      Departamento: 'Lambayeque',
      Provincia: 'Lambayeque',
      Distrito: 'San José',
    },
    { Departamento: 'Lambayeque', Provincia: 'Lambayeque', Distrito: 'Tucume' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Lima' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Ancón' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Ate' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Barranco' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Breña' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Carabayllo' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Chaclacayo' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Chorrillos' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Cieneguilla' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Comas' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'El Agustino' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Independencia' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Jesús María' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'La Molina' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'La Victoria' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Lince' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Los Olivos' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Lurigancho' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Lurin' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Magdalena del Mar' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Pueblo Libre' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Miraflores' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Pachacamac' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Pucusana' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Puente Piedra' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Punta Hermosa' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Punta Negra' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Rímac' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'San Bartolo' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'San Borja' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'San Isidro' },
    {
      Departamento: 'Lima',
      Provincia: 'Lima',
      Distrito: 'San Juan de Lurigancho',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Lima',
      Distrito: 'San Juan de Miraflores',
    },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'San Luis' },
    {
      Departamento: 'Lima',
      Provincia: 'Lima',
      Distrito: 'San Martín de Porres',
    },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'San Miguel' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Santa Anita' },
    {
      Departamento: 'Lima',
      Provincia: 'Lima',
      Distrito: 'Santa María del Mar',
    },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Santa Rosa' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Santiago de Surco' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Surquillo' },
    { Departamento: 'Lima', Provincia: 'Lima', Distrito: 'Villa El Salvador' },
    {
      Departamento: 'Lima',
      Provincia: 'Lima',
      Distrito: 'Villa María del Triunfo',
    },
    { Departamento: 'Lima', Provincia: 'Barranca', Distrito: 'Barranca' },
    { Departamento: 'Lima', Provincia: 'Barranca', Distrito: 'Paramonga' },
    { Departamento: 'Lima', Provincia: 'Barranca', Distrito: 'Pativilca' },
    { Departamento: 'Lima', Provincia: 'Barranca', Distrito: 'Supe' },
    { Departamento: 'Lima', Provincia: 'Barranca', Distrito: 'Supe Puerto' },
    { Departamento: 'Lima', Provincia: 'Cajatambo', Distrito: 'Cajatambo' },
    { Departamento: 'Lima', Provincia: 'Cajatambo', Distrito: 'Copa' },
    { Departamento: 'Lima', Provincia: 'Cajatambo', Distrito: 'Gorgor' },
    { Departamento: 'Lima', Provincia: 'Cajatambo', Distrito: 'Huancapon' },
    { Departamento: 'Lima', Provincia: 'Cajatambo', Distrito: 'Manas' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'Canta' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'Arahuay' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'Huamantanga' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'Huaros' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'Lachaqui' },
    { Departamento: 'Lima', Provincia: 'Canta', Distrito: 'San Buenaventura' },
    {
      Departamento: 'Lima',
      Provincia: 'Canta',
      Distrito: 'Santa Rosa de Quives',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Cañete',
      Distrito: 'San Vicente de Cañete',
    },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Asia' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Calango' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Cerro Azul' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Chilca' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Coayllo' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Imperial' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Lunahuana' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Mala' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Nuevo Imperial' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Pacaran' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Quilmana' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'San Antonio' },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'San Luis' },
    {
      Departamento: 'Lima',
      Provincia: 'Cañete',
      Distrito: 'Santa Cruz de Flores',
    },
    { Departamento: 'Lima', Provincia: 'Cañete', Distrito: 'Zúñiga' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Huaral' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Atavillos Alto' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Atavillos Bajo' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Aucallama' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Chancay' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Ihuari' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Lampian' },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Pacaraos' },
    {
      Departamento: 'Lima',
      Provincia: 'Huaral',
      Distrito: 'San Miguel de Acos',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huaral',
      Distrito: 'Santa Cruz de Andamarca',
    },
    { Departamento: 'Lima', Provincia: 'Huaral', Distrito: 'Sumbilca' },
    {
      Departamento: 'Lima',
      Provincia: 'Huaral',
      Distrito: 'Veintisiete de Noviembre',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Matucana' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Antioquia' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Callahuanca' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Carampoma' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Chicla' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Cuenca' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Huachupampa' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Huanza' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Huarochiri' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Lahuaytambo' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Langa' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Laraos' },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Mariatana' },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Ricardo Palma',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Andrés de Tupicocha',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'San Antonio' },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Bartolomé',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'San Damian' },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Juan de Iris',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Juan de Tantaranche',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Lorenzo de Quinti',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'San Mateo' },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Mateo de Otao',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Pedro de Casta',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'San Pedro de Huancayre',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Sangallaya' },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Santa Cruz de Cocachacra',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Santa Eulalia',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Santiago de Anchucaya',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Santiago de Tuna',
    },
    {
      Departamento: 'Lima',
      Provincia: 'Huarochirí',
      Distrito: 'Santo Domingo de Los Olleros',
    },
    { Departamento: 'Lima', Provincia: 'Huarochirí', Distrito: 'Surco' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Huacho' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Ambar' },
    {
      Departamento: 'Lima',
      Provincia: 'Huaura',
      Distrito: 'Caleta de Carquin',
    },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Checras' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Hualmay' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Huaura' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Leoncio Prado' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Paccho' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Santa Leonor' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Santa María' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Sayan' },
    { Departamento: 'Lima', Provincia: 'Huaura', Distrito: 'Vegueta' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Oyon' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Andajes' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Caujul' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Cochamarca' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Navan' },
    { Departamento: 'Lima', Provincia: 'Oyón', Distrito: 'Pachangara' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Yauyos' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Alis' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Allauca' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Ayaviri' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Azángaro' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Cacra' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Carania' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Catahuasi' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Chocos' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Cochas' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Colonia' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Hongos' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Huampara' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Huancaya' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Huangascar' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Huantan' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Huañec' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Laraos' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Lincha' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Madean' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Miraflores' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Omas' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Putinza' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Quinches' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Quinocay' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'San Joaquín' },
    {
      Departamento: 'Lima',
      Provincia: 'Yauyos',
      Distrito: 'San Pedro de Pilas',
    },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Tanta' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Tauripampa' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Tomas' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Tupe' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Viñac' },
    { Departamento: 'Lima', Provincia: 'Yauyos', Distrito: 'Vitis' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Iquitos' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Alto Nanay' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Fernando Lores' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Indiana' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Las Amazonas' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Mazan' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Napo' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Punchana' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Torres Causana' },
    { Departamento: 'Loreto', Provincia: 'Maynas', Distrito: 'Belén' },
    {
      Departamento: 'Loreto',
      Provincia: 'Maynas',
      Distrito: 'San Juan Bautista',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Alto Amazonas',
      Distrito: 'Yurimaguas',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Alto Amazonas',
      Distrito: 'Balsapuerto',
    },
    { Departamento: 'Loreto', Provincia: 'Alto Amazonas', Distrito: 'Jeberos' },
    { Departamento: 'Loreto', Provincia: 'Alto Amazonas', Distrito: 'Lagunas' },
    {
      Departamento: 'Loreto',
      Provincia: 'Alto Amazonas',
      Distrito: 'Santa Cruz',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Alto Amazonas',
      Distrito: 'Teniente Cesar López Rojas',
    },
    { Departamento: 'Loreto', Provincia: 'Loreto', Distrito: 'Nauta' },
    { Departamento: 'Loreto', Provincia: 'Loreto', Distrito: 'Parinari' },
    { Departamento: 'Loreto', Provincia: 'Loreto', Distrito: 'Tigre' },
    { Departamento: 'Loreto', Provincia: 'Loreto', Distrito: 'Trompeteros' },
    { Departamento: 'Loreto', Provincia: 'Loreto', Distrito: 'Urarinas' },
    {
      Departamento: 'Loreto',
      Provincia: 'Mariscal Ramón Castilla',
      Distrito: 'Ramón Castilla',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Mariscal Ramón Castilla',
      Distrito: 'Pebas',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Mariscal Ramón Castilla',
      Distrito: 'Yavari',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Mariscal Ramón Castilla',
      Distrito: 'San Pablo',
    },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Requena' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Alto Tapiche' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Capelo' },
    {
      Departamento: 'Loreto',
      Provincia: 'Requena',
      Distrito: 'Emilio San Martín',
    },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Maquia' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Puinahua' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Saquena' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Soplin' },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Tapiche' },
    {
      Departamento: 'Loreto',
      Provincia: 'Requena',
      Distrito: 'Jenaro Herrera',
    },
    { Departamento: 'Loreto', Provincia: 'Requena', Distrito: 'Yaquerana' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Contamana' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Inahuaya' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Padre Márquez' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Pampa Hermosa' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Sarayacu' },
    { Departamento: 'Loreto', Provincia: 'Ucayali', Distrito: 'Vargas Guerra' },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Barranca',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Cahuapanas',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Manseriche',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Morona',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Pastaza',
    },
    {
      Departamento: 'Loreto',
      Provincia: 'Datem del Marañón',
      Distrito: 'Andoas',
    },
    { Departamento: 'Loreto', Provincia: 'Putumayo', Distrito: 'Putumayo' },
    { Departamento: 'Loreto', Provincia: 'Putumayo', Distrito: 'Rosa Panduro' },
    {
      Departamento: 'Loreto',
      Provincia: 'Putumayo',
      Distrito: 'Teniente Manuel Clavero',
    },
    { Departamento: 'Loreto', Provincia: 'Putumayo', Distrito: 'Yaguas' },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tambopata',
      Distrito: 'Tambopata',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tambopata',
      Distrito: 'Inambari',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tambopata',
      Distrito: 'Las Piedras',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tambopata',
      Distrito: 'Laberinto',
    },
    { Departamento: 'Madre de Dios', Provincia: 'Manu', Distrito: 'Manu' },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Manu',
      Distrito: 'Fitzcarrald',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Manu',
      Distrito: 'Madre de Dios',
    },
    { Departamento: 'Madre de Dios', Provincia: 'Manu', Distrito: 'Huepetuhe' },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tahuamanu',
      Distrito: 'Iñapari',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tahuamanu',
      Distrito: 'Iberia',
    },
    {
      Departamento: 'Madre de Dios',
      Provincia: 'Tahuamanu',
      Distrito: 'Tahuamanu',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'Moquegua',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'Carumas',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'Cuchumbaya',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'Samegua',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'San Cristóbal',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'Mariscal Nieto',
      Distrito: 'Torata',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Omate',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Chojata',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Coalaque',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Ichuña',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'La Capilla',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Lloque',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Matalaque',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Puquina',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Quinistaquillas',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Ubinas',
    },
    {
      Departamento: 'Moquegua',
      Provincia: 'General Sánchez Cerro',
      Distrito: 'Yunga',
    },
    { Departamento: 'Moquegua', Provincia: 'Ilo', Distrito: 'Ilo' },
    { Departamento: 'Moquegua', Provincia: 'Ilo', Distrito: 'El Algarrobal' },
    { Departamento: 'Moquegua', Provincia: 'Ilo', Distrito: 'Pacocha' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Chaupimarca' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Huachon' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Huariaca' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Huayllay' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Ninacaca' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Pallanchacra' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Paucartambo' },
    {
      Departamento: 'Pasco',
      Provincia: 'Pasco',
      Distrito: 'San Francisco de Asís de Yarusyacan',
    },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Simon Bolívar' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Ticlacayan' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Tinyahuarco' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Vicco' },
    { Departamento: 'Pasco', Provincia: 'Pasco', Distrito: 'Yanacancha' },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Yanahuanca',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Chacayan',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Goyllarisquizga',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Paucar',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'San Pedro de Pillao',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Santa Ana de Tusi',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Tapuc',
    },
    {
      Departamento: 'Pasco',
      Provincia: 'Daniel Alcides Carrión',
      Distrito: 'Vilcabamba',
    },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Oxapampa' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Chontabamba' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Huancabamba' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Palcazu' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Pozuzo' },
    {
      Departamento: 'Pasco',
      Provincia: 'Oxapampa',
      Distrito: 'Puerto Bermúdez',
    },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Villa Rica' },
    { Departamento: 'Pasco', Provincia: 'Oxapampa', Distrito: 'Constitución' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Piura' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Castilla' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Catacaos' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Cura Mori' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'El Tallan' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'La Arena' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'La Unión' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Las Lomas' },
    { Departamento: 'Piura', Provincia: 'Piura', Distrito: 'Tambo Grande' },
    {
      Departamento: 'Piura',
      Provincia: 'Piura',
      Distrito: 'Veintiseis de Octubre',
    },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Ayabaca' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Frias' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Jilili' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Lagunas' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Montero' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Pacaipampa' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Paimas' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Sapillica' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Sicchez' },
    { Departamento: 'Piura', Provincia: 'Ayabaca', Distrito: 'Suyo' },
    {
      Departamento: 'Piura',
      Provincia: 'Huancabamba',
      Distrito: 'Huancabamba',
    },
    { Departamento: 'Piura', Provincia: 'Huancabamba', Distrito: 'Canchaque' },
    {
      Departamento: 'Piura',
      Provincia: 'Huancabamba',
      Distrito: 'El Carmen de la Frontera',
    },
    { Departamento: 'Piura', Provincia: 'Huancabamba', Distrito: 'Huarmaca' },
    { Departamento: 'Piura', Provincia: 'Huancabamba', Distrito: 'Lalaquiz' },
    {
      Departamento: 'Piura',
      Provincia: 'Huancabamba',
      Distrito: 'San Miguel de El Faique',
    },
    { Departamento: 'Piura', Provincia: 'Huancabamba', Distrito: 'Sondor' },
    { Departamento: 'Piura', Provincia: 'Huancabamba', Distrito: 'Sondorillo' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Chulucanas' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Buenos Aires' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Chalaco' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'La Matanza' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Morropon' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Salitral' },
    {
      Departamento: 'Piura',
      Provincia: 'Morropón',
      Distrito: 'San Juan de Bigote',
    },
    {
      Departamento: 'Piura',
      Provincia: 'Morropón',
      Distrito: 'Santa Catalina de Mossa',
    },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Santo Domingo' },
    { Departamento: 'Piura', Provincia: 'Morropón', Distrito: 'Yamango' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Paita' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Amotape' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Arenal' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Colan' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'La Huaca' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Tamarindo' },
    { Departamento: 'Piura', Provincia: 'Paita', Distrito: 'Vichayal' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Sullana' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Bellavista' },
    {
      Departamento: 'Piura',
      Provincia: 'Sullana',
      Distrito: 'Ignacio Escudero',
    },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Lancones' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Marcavelica' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Miguel Checa' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Querecotillo' },
    { Departamento: 'Piura', Provincia: 'Sullana', Distrito: 'Salitral' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'Pariñas' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'El Alto' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'La Brea' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'Lobitos' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'Los Organos' },
    { Departamento: 'Piura', Provincia: 'Talara', Distrito: 'Mancora' },
    { Departamento: 'Piura', Provincia: 'Sechura', Distrito: 'Sechura' },
    {
      Departamento: 'Piura',
      Provincia: 'Sechura',
      Distrito: 'Bellavista de la Unión',
    },
    { Departamento: 'Piura', Provincia: 'Sechura', Distrito: 'Bernal' },
    {
      Departamento: 'Piura',
      Provincia: 'Sechura',
      Distrito: 'Cristo Nos Valga',
    },
    { Departamento: 'Piura', Provincia: 'Sechura', Distrito: 'Vice' },
    {
      Departamento: 'Piura',
      Provincia: 'Sechura',
      Distrito: 'Rinconada Llicuar',
    },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Puno' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Acora' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Amantani' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Atuncolla' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Capachica' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Chucuito' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Coata' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Huata' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Mañazo' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Paucarcolla' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Pichacani' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Plateria' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'San Antonio' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Tiquillaca' },
    { Departamento: 'Puno', Provincia: 'Puno', Distrito: 'Vilque' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Azángaro' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Achaya' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Arapa' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Asillo' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Caminaca' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Chupa' },
    {
      Departamento: 'Puno',
      Provincia: 'Azángaro',
      Distrito: 'José Domingo Choquehuanca',
    },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Muñani' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Potoni' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Saman' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'San Anton' },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'San José' },
    {
      Departamento: 'Puno',
      Provincia: 'Azángaro',
      Distrito: 'San Juan de Salinas',
    },
    {
      Departamento: 'Puno',
      Provincia: 'Azángaro',
      Distrito: 'Santiago de Pupuja',
    },
    { Departamento: 'Puno', Provincia: 'Azángaro', Distrito: 'Tirapata' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Macusani' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Ajoyani' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Ayapata' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Coasa' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Corani' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Crucero' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Ituata' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Ollachea' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'San Gaban' },
    { Departamento: 'Puno', Provincia: 'Carabaya', Distrito: 'Usicayos' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Juli' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Desaguadero' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Huacullani' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Kelluyo' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Pisacoma' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Pomata' },
    { Departamento: 'Puno', Provincia: 'Chucuito', Distrito: 'Zepita' },
    { Departamento: 'Puno', Provincia: 'El Collao', Distrito: 'Ilave' },
    { Departamento: 'Puno', Provincia: 'El Collao', Distrito: 'Capazo' },
    { Departamento: 'Puno', Provincia: 'El Collao', Distrito: 'Pilcuyo' },
    { Departamento: 'Puno', Provincia: 'El Collao', Distrito: 'Santa Rosa' },
    { Departamento: 'Puno', Provincia: 'El Collao', Distrito: 'Conduriri' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Huancane' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Cojata' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Huatasani' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Inchupalla' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Pusi' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Rosaspata' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Taraco' },
    { Departamento: 'Puno', Provincia: 'Huancané', Distrito: 'Vilque Chico' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Lampa' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Cabanilla' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Calapuja' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Nicasio' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Ocuviri' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Palca' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Paratia' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Pucara' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Santa Lucia' },
    { Departamento: 'Puno', Provincia: 'Lampa', Distrito: 'Vilavila' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Ayaviri' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Antauta' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Cupi' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Llalli' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Macari' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Nuñoa' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Orurillo' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Santa Rosa' },
    { Departamento: 'Puno', Provincia: 'Melgar', Distrito: 'Umachiri' },
    { Departamento: 'Puno', Provincia: 'Moho', Distrito: 'Moho' },
    { Departamento: 'Puno', Provincia: 'Moho', Distrito: 'Conima' },
    { Departamento: 'Puno', Provincia: 'Moho', Distrito: 'Huayrapata' },
    { Departamento: 'Puno', Provincia: 'Moho', Distrito: 'Tilali' },
    {
      Departamento: 'Puno',
      Provincia: 'San Antonio de Putina',
      Distrito: 'Putina',
    },
    {
      Departamento: 'Puno',
      Provincia: 'San Antonio de Putina',
      Distrito: 'Ananea',
    },
    {
      Departamento: 'Puno',
      Provincia: 'San Antonio de Putina',
      Distrito: 'Pedro Vilca Apaza',
    },
    {
      Departamento: 'Puno',
      Provincia: 'San Antonio de Putina',
      Distrito: 'Quilcapuncu',
    },
    {
      Departamento: 'Puno',
      Provincia: 'San Antonio de Putina',
      Distrito: 'Sina',
    },
    { Departamento: 'Puno', Provincia: 'San Román', Distrito: 'Juliaca' },
    { Departamento: 'Puno', Provincia: 'San Román', Distrito: 'Cabana' },
    { Departamento: 'Puno', Provincia: 'San Román', Distrito: 'Cabanillas' },
    { Departamento: 'Puno', Provincia: 'San Román', Distrito: 'Caracoto' },
    { Departamento: 'Puno', Provincia: 'San Román', Distrito: 'San Miguel' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Sandia' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Cuyocuyo' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Limbani' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Patambuco' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Phara' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Quiaca' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'San Juan del Oro' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Yanahuaya' },
    { Departamento: 'Puno', Provincia: 'Sandia', Distrito: 'Alto Inambari' },
    {
      Departamento: 'Puno',
      Provincia: 'Sandia',
      Distrito: 'San Pedro de Putina Punco',
    },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Yunguyo' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Anapia' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Copani' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Cuturapi' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Ollaraya' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Tinicachi' },
    { Departamento: 'Puno', Provincia: 'Yunguyo', Distrito: 'Unicachi' },
    {
      Departamento: 'San Martín',
      Provincia: 'Moyobamba',
      Distrito: 'Moyobamba',
    },
    { Departamento: 'San Martín', Provincia: 'Moyobamba', Distrito: 'Calzada' },
    { Departamento: 'San Martín', Provincia: 'Moyobamba', Distrito: 'Habana' },
    {
      Departamento: 'San Martín',
      Provincia: 'Moyobamba',
      Distrito: 'Jepelacio',
    },
    { Departamento: 'San Martín', Provincia: 'Moyobamba', Distrito: 'Soritor' },
    { Departamento: 'San Martín', Provincia: 'Moyobamba', Distrito: 'Yantalo' },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'Bellavista',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'Alto Biavo',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'Bajo Biavo',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'Huallaga',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'San Pablo',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Bellavista',
      Distrito: 'San Rafael',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'El Dorado',
      Distrito: 'San José de Sisa',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'El Dorado',
      Distrito: 'Agua Blanca',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'El Dorado',
      Distrito: 'San Martín',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'El Dorado',
      Distrito: 'Santa Rosa',
    },
    { Departamento: 'San Martín', Provincia: 'El Dorado', Distrito: 'Shatoja' },
    { Departamento: 'San Martín', Provincia: 'Huallaga', Distrito: 'Saposoa' },
    {
      Departamento: 'San Martín',
      Provincia: 'Huallaga',
      Distrito: 'Alto Saposoa',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Huallaga',
      Distrito: 'El Eslabón',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Huallaga',
      Distrito: 'Piscoyacu',
    },
    { Departamento: 'San Martín', Provincia: 'Huallaga', Distrito: 'Sacanche' },
    {
      Departamento: 'San Martín',
      Provincia: 'Huallaga',
      Distrito: 'Tingo de Saposoa',
    },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Lamas' },
    {
      Departamento: 'San Martín',
      Provincia: 'Lamas',
      Distrito: 'Alonso de Alvarado',
    },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Barranquita' },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Caynarachi' },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Cuñumbuqui' },
    {
      Departamento: 'San Martín',
      Provincia: 'Lamas',
      Distrito: 'Pinto Recodo',
    },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Rumisapa' },
    {
      Departamento: 'San Martín',
      Provincia: 'Lamas',
      Distrito: 'San Roque de Cumbaza',
    },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Shanao' },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Tabalosos' },
    { Departamento: 'San Martín', Provincia: 'Lamas', Distrito: 'Zapatero' },
    {
      Departamento: 'San Martín',
      Provincia: 'Mariscal Cáceres',
      Distrito: 'Juanjuí',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Mariscal Cáceres',
      Distrito: 'Campanilla',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Mariscal Cáceres',
      Distrito: 'Huicungo',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Mariscal Cáceres',
      Distrito: 'Pachiza',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Mariscal Cáceres',
      Distrito: 'Pajarillo',
    },
    { Departamento: 'San Martín', Provincia: 'Picota', Distrito: 'Picota' },
    {
      Departamento: 'San Martín',
      Provincia: 'Picota',
      Distrito: 'Buenos Aires',
    },
    { Departamento: 'San Martín', Provincia: 'Picota', Distrito: 'Caspisapa' },
    { Departamento: 'San Martín', Provincia: 'Picota', Distrito: 'Pilluana' },
    { Departamento: 'San Martín', Provincia: 'Picota', Distrito: 'Pucacaca' },
    {
      Departamento: 'San Martín',
      Provincia: 'Picota',
      Distrito: 'San Cristóbal',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Picota',
      Distrito: 'San Hilarión',
    },
    { Departamento: 'San Martín', Provincia: 'Picota', Distrito: 'Shamboyacu' },
    {
      Departamento: 'San Martín',
      Provincia: 'Picota',
      Distrito: 'Tingo de Ponasa',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Picota',
      Distrito: 'Tres Unidos',
    },
    { Departamento: 'San Martín', Provincia: 'Rioja', Distrito: 'Rioja' },
    { Departamento: 'San Martín', Provincia: 'Rioja', Distrito: 'Awajun' },
    {
      Departamento: 'San Martín',
      Provincia: 'Rioja',
      Distrito: 'Elías Soplin Vargas',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Rioja',
      Distrito: 'Nueva Cajamarca',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'Rioja',
      Distrito: 'Pardo Miguel',
    },
    { Departamento: 'San Martín', Provincia: 'Rioja', Distrito: 'Posic' },
    {
      Departamento: 'San Martín',
      Provincia: 'Rioja',
      Distrito: 'San Fernando',
    },
    { Departamento: 'San Martín', Provincia: 'Rioja', Distrito: 'Yorongos' },
    { Departamento: 'San Martín', Provincia: 'Rioja', Distrito: 'Yuracyacu' },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Tarapoto',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Alberto Leveau',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Cacatachi',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Chazuta',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Chipurana',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'El Porvenir',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Huimbayoc',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Juan Guerra',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'La Banda de Shilcayo',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Morales',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Papaplaya',
    },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'San Antonio',
    },
    { Departamento: 'San Martín', Provincia: 'San Martín', Distrito: 'Sauce' },
    {
      Departamento: 'San Martín',
      Provincia: 'San Martín',
      Distrito: 'Shapaja',
    },
    { Departamento: 'San Martín', Provincia: 'Tocache', Distrito: 'Tocache' },
    {
      Departamento: 'San Martín',
      Provincia: 'Tocache',
      Distrito: 'Nuevo Progreso',
    },
    { Departamento: 'San Martín', Provincia: 'Tocache', Distrito: 'Polvora' },
    { Departamento: 'San Martín', Provincia: 'Tocache', Distrito: 'Shunte' },
    { Departamento: 'San Martín', Provincia: 'Tocache', Distrito: 'Uchiza' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Tacna' },
    {
      Departamento: 'Tacna',
      Provincia: 'Tacna',
      Distrito: 'Alto de la Alianza',
    },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Calana' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Ciudad Nueva' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Inclan' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Pachia' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Palca' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Pocollay' },
    { Departamento: 'Tacna', Provincia: 'Tacna', Distrito: 'Sama' },
    {
      Departamento: 'Tacna',
      Provincia: 'Tacna',
      Distrito: 'Coronel Gregorio Albarracín Lanchipa',
    },
    {
      Departamento: 'Tacna',
      Provincia: 'Tacna',
      Distrito: 'La Yarada los Palos',
    },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Candarave' },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Cairani' },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Camilaca' },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Curibaya' },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Huanuara' },
    { Departamento: 'Tacna', Provincia: 'Candarave', Distrito: 'Quilahuani' },
    { Departamento: 'Tacna', Provincia: 'Jorge Basadre', Distrito: 'Locumba' },
    { Departamento: 'Tacna', Provincia: 'Jorge Basadre', Distrito: 'Ilabaya' },
    { Departamento: 'Tacna', Provincia: 'Jorge Basadre', Distrito: 'Ite' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Tarata' },
    {
      Departamento: 'Tacna',
      Provincia: 'Tarata',
      Distrito: 'Héroes Albarracín',
    },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Estique' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Estique-Pampa' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Sitajara' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Susapaya' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Tarucachi' },
    { Departamento: 'Tacna', Provincia: 'Tarata', Distrito: 'Ticaco' },
    { Departamento: 'Tumbes', Provincia: 'Tumbes', Distrito: 'Tumbes' },
    { Departamento: 'Tumbes', Provincia: 'Tumbes', Distrito: 'Corrales' },
    { Departamento: 'Tumbes', Provincia: 'Tumbes', Distrito: 'La Cruz' },
    {
      Departamento: 'Tumbes',
      Provincia: 'Tumbes',
      Distrito: 'Pampas de Hospital',
    },
    { Departamento: 'Tumbes', Provincia: 'Tumbes', Distrito: 'San Jacinto' },
    {
      Departamento: 'Tumbes',
      Provincia: 'Tumbes',
      Distrito: 'San Juan de la Virgen',
    },
    {
      Departamento: 'Tumbes',
      Provincia: 'Contralmirante Villar',
      Distrito: 'Zorritos',
    },
    {
      Departamento: 'Tumbes',
      Provincia: 'Contralmirante Villar',
      Distrito: 'Casitas',
    },
    {
      Departamento: 'Tumbes',
      Provincia: 'Contralmirante Villar',
      Distrito: 'Canoas de Punta Sal',
    },
    { Departamento: 'Tumbes', Provincia: 'Zarumilla', Distrito: 'Zarumilla' },
    {
      Departamento: 'Tumbes',
      Provincia: 'Zarumilla',
      Distrito: 'Aguas Verdes',
    },
    { Departamento: 'Tumbes', Provincia: 'Zarumilla', Distrito: 'Matapalo' },
    { Departamento: 'Tumbes', Provincia: 'Zarumilla', Distrito: 'Papayal' },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Calleria',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Campoverde',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Iparia',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Masisea',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Yarinacocha',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Nueva Requena',
    },
    {
      Departamento: 'Ucayali',
      Provincia: 'Coronel Portillo',
      Distrito: 'Manantay',
    },
    { Departamento: 'Ucayali', Provincia: 'Atalaya', Distrito: 'Raymondi' },
    { Departamento: 'Ucayali', Provincia: 'Atalaya', Distrito: 'Sepahua' },
    { Departamento: 'Ucayali', Provincia: 'Atalaya', Distrito: 'Tahuania' },
    { Departamento: 'Ucayali', Provincia: 'Atalaya', Distrito: 'Yurua' },
    {
      Departamento: 'Ucayali',
      Provincia: 'Padre Abad',
      Distrito: 'Padre Abad',
    },
    { Departamento: 'Ucayali', Provincia: 'Padre Abad', Distrito: 'Irazola' },
    { Departamento: 'Ucayali', Provincia: 'Padre Abad', Distrito: 'Curimana' },
    { Departamento: 'Ucayali', Provincia: 'Padre Abad', Distrito: 'Neshuya' },
    {
      Departamento: 'Ucayali',
      Provincia: 'Padre Abad',
      Distrito: 'Alexander Von Humboldt',
    },
    { Departamento: 'Ucayali', Provincia: 'Purús', Distrito: 'Purus' },
  ]

  public static MensajesPersonalizados = {
    NoExisteToken: 'El parámetro token no se encuentra como parte de la URL.',
    NoExisteIdEMO: 'El parámetro idemo no se encuentra como parte de la URL.',
    NoExisteIdCapacitacion:
      'El parámetro idcapacitacion no se encuentra como parte de la URL.',
    NoAccesoTriajeInicial:
      'El usuario ya ha completado su triaje inicial, favor de acceder a la página correcta.',
    SinInformacion: 'No se encontró información con el parámetro indicado.',
    errorGenerico:
      'Something went wrong!. Please contact Help Desk for support.',
    CamposObligatoriosContinuar:
      'Para continuar debe completar los campos obligatorios y presionar Continuar.',
    CamposObligatoriosTerminar:
      'Para continuar debe completar los campos obligatorios y presionar Terminar.',
    CamposObligatoriosFirmaTerminos:
      'Para continuar debe ingresar su firma electrónica y aceptar los términos y condiciones, luego presionar Continuar.',
    CamposObligatoriosTerminos:
      'Para continuar debe aceptar los términos y condiciones, luego presionar Continuar.',
    MensajeValidacionArchivoPDF:
      'Solo debe seleccionar un archivo con formato PDF.',
    MensajeExitoGuardarDocumentoColaborador:
      'Se guardó correctamente el documento del colaborador.',
    CamposObligatoriosEnviar:
      'Para continuar debe completar los campos obligatorios y presionar Enviar.',
    MensajeValidacionArchivoTamanio:
      'El tamaño del archivo seleccionado no debe exceder los 10 MB.',
    SoloValoresPositivos: 'Solo se permite valores positivos.',
  }

  public static Urls = {
    GetGlobalCompania: '/configuracion/GetGlobalCompania',
    ObtenerBranding: '/configuracion/ObtenerBranding',
    CentroCostoObtener: '/centroCosto/CentroCostoObtener',
    TrabajadorDetalle: '/user/TrabajadorDetalle',
    UsuarioActualizarCorreoNumero: '/user/UsuarioActualizarCorreoNumero',
    ColaboradorObtenerEmpresa: '/user/ColaboradorObtenerEmpresa',
    ObtenerDatosColaborador: '/user/ObtenerColaborador',
    ActualizarDatosColaborador: '/covid/ActualizarColaborador',
    ObtenerCuestionario: '/covid/ObtenerCuestionario',
    LoginColaborador: '/user/LoginColaboradorDev',
    GuardarTriajeInicial: '/covid/GuardarTriajeInicial',
    GuardarTriajeDiario: '/covid/GuardarTriajeDiario',
    RegistrarFirma: '/user/RegistrarFirma',
    RegistrarFirmaResultadoEmo: '/emo/FirmaHojaResultadoEmo',
    RecuperarPassword: '/user/UsuarioResetearPassword',
    UsuarioResetearPasswordByNroDoc: '/user/UsuarioResetearPasswordByNroDoc',
    RegistrarDocumentoColaborador: '/configuracion/DocumentoRegistrar',
    ObtenerHojaResultadoEMO: '/emo/ObtenerHojaResultadoEMO',
    ObtenerDetalleEMO: '/emo/EmoDetalle',
    ObtenerCapacitaciones: '/capacitacion/CapacitacionListar',
    CapacitacionGrupoObtener: '/capacitacion/CapacitacionGrupoObtener',
    CapacitacionActivaListar: '/capacitacion/CapacitacionActivaListar',
    CapacitacionAgregarColaborador:
      '/capacitacion/CapacitacionAgregarColaborador',
    ObtenerPruebas: '/covidDos/PruebaListar',
    FirmarCapacitacionPendiente: '/capacitacion/CapacitacionFirmaPendiente',
    ObteneCertificadoCapacitacion:
      '/capacitacion/CapacitacionObtenerCertificado',
    ObtenerConfiguracion: '/configuracion/ObtenerConfiguracionCompania',
    ConfiguracionObtener: '/configuracion/ConfiguracionObtener',
    EmoPDFObservacionRecomendaciones: '/emo/EmoPDFObservacion',
    EmoPDFCompromiso: '/emo/EmoPDFCompromiso',
    UsuarioImcCrear: '/nutricion/UsuarioImcCrear',
    FormularioSaludCrear: '/covidUno/FormularioSaludCrear',
    ObtenerBeneficio: '/covid/ObtenerBeneficio',
    GuardarCanje: '/covid/GuardarCanje',
    ObtenerPais: '/configuracion/PaisObtener',
    ObtenerVacunas: '/covid/TipoPruebaObtener',
    PruebaVacunaCrear: '/covidDos/PruebaVacunaCrear',
    ObtenerCompania: '/configuracion/CompaniaObtener',
    ObtenerConfiguracionCompania: '/configuracion/ObtenerConfiguracionCompania',
    ListarVacunas: '/covidDos/PruebaVacunaListar',
    VacunaPendiente: '/covidDos/VacunaPendiente',
    EmoConsultaRegistrar: '/emo/EmoConsultaRegistrar',
    EmoCompromisaFirmaRegistrar: '/emo/EmoCompromisoFirmaRegistrar',
    ListarCentroMedico: '/emo/CentroMedicoObtener',
    SolicitarProgramacionEmo: '/emo/SolicitarProgramacionEmo',
    ReunionObtener: '/comite/ReunionObtener',
    FirmarReunion: '/comite/ReunionFirmar',
    ObtenerReunion: '/comite/ReunionObtener',
    ObtenerListadoAcuerdos: '/comite/AcuerdoListar',
    ObtenerSubtipoIncidente: '/incidente/SubtipoIncidenteObtener',
    ObtenerGravedadIncidente: '/incidente/GravedadIncidenteObtener',
    ObtenerTipoIncidente: '/incidente/TipoIncidenteObtener',
    ObtenerUsuariosAlertaRP: '/incidente/ObtenerUsuariosAlertaRP',
    ObtenerTrabajadorNombreNumDoc: '/user/TrabajadorObtenerPorNombreYNumDoc',
    ObtenerTrabajadorTipoNumDocumento:
      '/user/TrabajadorObtenerTipoNumDocumento',
    IncidenteRegistrar: '/incidente/IncidenteRegistrar',
    IncidentePreliminarEditar: '/incidente/IncidentePreliminarEditar',

    EliminarDocumentoIncidente: '/configuracion/DocumentoEliminar',
    RegistrarDocumentoIncidente: '/configuracion/DocumentoRegistrar',
    ListarDocumentosIncidente: '/configuracion/DocumentoListar',
    ActualizarEstadoIncidente: '/incidente/IncidenteActualizarEstado',
    ActualizarIncidente: '/incidente/IncidenteEditar',
    ObtenerRegistroAccidente: '/incidente/RegistroAccidenteObtener',
    ObtenerTipoDano: '/incidente/TipoDanoObtener',
    UsuarioRegistrarAsistencia: '/user/UsuarioRegistrarAsistencia',
    ResponsableMCBuscarNombreNumDoc: '/user/ResponsableMCBuscarNombreNumDoc',

    AcTipoReaccionObtener: '/actoCondicion/AcTipoReaccionObtener',
    SedeObtener: '/sede/SedeObtener',
    ListarAreas: '/area/AreaObtener',
    ListarUnidades: '/unidad/UnidadObtener',
    AcTipoActoObtener: '/actoCondicion/AcTipoActoObtener',
    AcTipoCondicionObtener: '/actoCondicion/AcTipoCondicionObtener',
    ActoCondicionCrear: '/actoCondicion/ActoCondicionCrear',
    ActoCondicionSubirImagen: '/actoCondicion/ActoCondicionSubirImagen',

    AcTipoHallazgoObtener: '/actoCondicion/AcTipoHallazgoObtener',
    ActoCondicionConsultarCodigo: '/actoCondicion/ActoCondicionConsultarCodigo',
    AcTipoHallazgoCategoriaObtener:
      '/actoCondicion/AcTipoHallazgoCategoriaObtener',
    ActoCondicionListarPorUsuario:
      '/actoCondicion/ActoCondicionListarPorUsuario',
    ActoCondicionObtener: '/actoCondicion/ActoCondicionObtener',
    MedidaCorrectivaPorActoCondicion:
      '/incidente/MedidaCorrectivaPorActoCondicion',
    ActoCondicionListarEmpresasIXM:
      '/actoCondicion/ActoCondicionListarEmpresasIXM',
    ActoCondicionListar: '/actoCondicion/ActoCondicionListar',
    AcObtenerResponsablePorAreaSede:
      '/actoCondicion/AcObtenerResponsablePorAreaSede',
    AcListarResponsablePorAreaSede:
      '/actoCondicion/AcListarResponsablePorAreaSede',
    AcEquipoObtener: '/actoCondicion/AcEquipoObtener',
    IncidenteObtenerPorResponsable: '/incidente/IncidenteObtenerPorResponsable',
    DatosDetalleIncidenteObtener: '/incidente/DatosDetalleIncidenteObtener',
    IncidenteSubirImagen: '/incidente/IncidenteSubirImagen',
    IncidenteFirmarInvestigacion: '/incidente/IncidenteFirmarInvestigacion',
    ClasificacionAccidenteObtener: '/incidente/ClasificacionAccidenteObtener',
    RegistroAccidenteObtener: '/incidente/RegistroAccidenteObtener',
    ObtenerRazonSocial: '/razonSocial/RazonSocialObtener',
    ObtenerIncidente: '/incidente/IncidenteDetalle',
    ObtenerListaIncidente: '/incidente/IncidenteObtener',
    NaturalezaLesionObtener: '/incidente/NaturalezaLesionObtener',
    IncidentePreliminarDetalle: '/incidente/IncidentePreliminarDetalle',

    EmoLeerInterconsulta: '/emo/EmoLeerInterconsulta',
    EmoActualizarFechaDocumento: '/emo/EmoActualizarFechaDocumento',
    TipoDocumentoObtener: '/user/TipoDocumentoObtener',

    InspeccionCrear: '/inspeccion/InspeccionCrear',
    InspeccionListar: '/inspeccion/InspeccionListar',
    InspeccionDetalle: '/inspeccion/InspeccionDetalle',
    IpFormularioInspeccionObtener: '/inspeccion/IpFormularioInspeccionObtener',
    InspeccionFormularioDetalle: '/inspeccion/InspeccionFormularioDetalle',
    InspeccionFormularioRespuestaCrear:
      '/inspeccion/InspeccionFormularioRespuestaCrear',
    InspeccionFormularioRespuestaDetalle:
      '/inspeccion/InspeccionFormularioRespuestaDetalle',
    InspeccionPDFDetalle: '/inspeccion/InspeccionPDFDetalle',
    InspeccionFirmar: '/inspeccion/InspeccionFirmar',
    InspeccionMCEnviarAprobar: '/inspeccion/InspeccionMCEnviarAprobar',
    MedidaCorrectivaEnviarAprobacion:
      '/incidente/MedidaCorrectivaEnviarAprobacion',
    ActoCondicionMCEnviarAprobar: '/actoCondicion/ActoCondicionMCEnviarAprobar',
    ActualizarEstadoAcuerdo: '/comite/AcuerdoActualizarEstado',

    AtsCodigoMaquinaObtener: '/ats/AtsCodigoMaquinaObtener',
    AtsEppsObtener: '/ats/AtsEppsObtener',
    AtsHerramientaObtener: '/ats/AtsHerramientaObtener',
    AtsPeligrosObtener: '/ats/AtsPeligrosObtener',
    AtsPermisoTrabajoRiesgoObtener: '/ats/AtsPermisoTrabajoRiesgoObtener',
    AtsSpcEquiposEmergenciaObtener: '/ats/AtsSpcEquiposEmergenciaObtener',
    AtsRegistrar: '/ats/AtsRegistrar',
    AtsGuardarFirma: '/ats/AtsGuardarFirma',
    AtsCodigoMaquinaBuscar: '/ats/AtsCodigoMaquinaBuscar',
    AtsObtenerUltimos: '/ats/AtsObtenerUltimos',
    AtsDetalle: '/ats/AtsDetalle',
    AtsActualizarEstado: '/ats/AtsActualizarEstado',
    AtsPDFObtener: '/ats/AtsPDFObtener',
    AtsObtener: '/ats/AtsObtener',
    AtsResponsableFirmar: '/ats/AtsResponsableFirmar',

    PetarTrabajosPeligrososObtener: '/petar/PetarTrabajosPeligrososObtener',
    PetarRegistrar: '/petar/PetarRegistrar',
    PetarDetalle: '/petar/PetarDetalle',

    DJSaludRegistrar: '/covidUno/DJSaludRegistrar',
    UsuarioActualizarJefe: '/user/UsuarioActualizarJefe',
    DJSaludGuardarFirma: '/covidUno/DJSaludGuardarFirma',

    CapacitacionDetalle: '/capacitacion/CapacitacionDetalle',

    CitaListar: '/nutricion/CitaListar',
    CitaDetalle: '/nutricion/CitaDetalle',
    CitaEventosListar: '/nutricion/CitaEventosListar',
    ObtenerSupervisor: '/actoCondicion/ObtenerSupervisor',
    AcObtenerAreaPorSede: '/actoCondicion/AcObtenerAreaPorSede',

    EppTipoProteccionObtener: '/epp/EppTipoProteccionObtener',
    EppTipoCategoriaObtener: '/epp/EppTipoCategoriaObtener',
    EppEquipoListar: '/epp/EppEquipoListar',
    EppSolicitudDetalle: '/epp/EppSolicitudDetalle',
    EppMotivoSolicitudObtener: '/epp/EppMotivoSolicitudObtener',
    EppSolicitudCrear: '/epp/EppSolicitudCrear',
    EppSolicitudObtener: '/epp/EppSolicitudObtener',
    RequestPath: '/configuracion/RequestPath',
    MedidaCorrectivaGlobalObtenerPorUsuario:
      '/incidente/MedidaCorrectivaGlobalObtenerPorUsuario',
    EPPCrear: '/epp/EppCrear',
    EppFirmarEntrega: '/epp/EppFirmarEntrega',
    ObtenerKardex: '/epp/EppKardexObtener',

    LoginColaboradorDev: '/user/LoginColaboradorDev',
  }

  public static Mensajes = {
    NoExisteToken: 'El parámetro token no se encuentra como parte de la URL.',
    MensajeSinToken:
      'El usuario debe acceder con sus credenciales para poder utilizar el sistema.',
  }
}
