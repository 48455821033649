import React, { lazy, Suspense } from 'react'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ParametrosNoAdministrables } from './genericos/VariablesGlobales'

const ATSDetalle = lazy(() => import('./components/ATSDetalle/ATSDetalle'))

const FirmaResponsableATS = lazy(() =>
  import('./components/FirmaResponsableATS/FirmaResponsableATS'),
)

const RegistroPetar = lazy(() =>
  import('./components/RegistroPetar/RegistroPetar'),
)

const EstadisticasRACs = lazy(() =>
  import('./components/EstadisticasRACs/EstadisticasRACs'),
)

const IncidenteDetalle = lazy(() =>
  import('./components/IncidenteDetalle/IncidenteDetalle'),
)

const EntregaEPP = lazy(() => import('./components/EntregaEpp/EntregaEpp'))
const MisAcciones = lazy(() => import('./components/MisAcciones/MisAcciones'))
const EnviarAprobacionMC = lazy(() =>
  import('./components/EnviarAprobacionMC/EnviarAprobacionMC'),
)

const ActualizarDatos = lazy(() =>
  import('./components/ActualizarDatos/ActualizarDatos'),
)

const DetalleInspeccionPDF = lazy(() =>
  import('./components/DetalleInspeccionPDF/DetalleInspeccionPDF'),
)

const EvolucionNutricional = lazy(() =>
  import('./components/EvolucionNutricional/EvolucionNutricional'),
)

const DetalleInspeccionContratista = lazy(() =>
  import(
    './components/DetalleInspeccionContratista/DetalleInspeccionContratista'
  ),
)

const SolicitarEPP = lazy(() =>
  import('./components/SolicitarEPP/SolicitarEPP'),
)

const ListadoHallazgos = lazy(() =>
  import('./components/ListadoHallazgos/ListadoHallazgos'),
)

const ListadoATS = lazy(() => import('./components/ListadoATS/ListadoATS'))

const Login = lazy(() => import('./components/Login/Login'))
const RegistrarDatos = lazy(() =>
  import('./components/RegistrarDatos/RegistrarDatos'),
)
const TriajeDiario = lazy(() =>
  import('./components/TriajeDiario/TriajeDiario'),
)
const TriajeUnico = lazy(() => import('./components/TriajeUnico/TriajeUnico'))
const Home = lazy(() => import('./components/Home/Home'))
const VisorQR = lazy(() => import('./components/VisorQR/VisorQR'))
const DocumentoQR = lazy(() => import('./components/DocumentoQR/DocumentoQR'))
const TriajeRealizado = lazy(() =>
  import('./components/TriajeRealizado/TriajeRealizado'),
)
const FirmaElectronica = lazy(() =>
  import('./components/FirmaElectronica/FirmaElectronica'),
)
const IngresoContrasenia = lazy(() =>
  import('./components/IngresoContrasenia/IngresoContrasenia'),
)
const RestablecerContrasenia = lazy(() =>
  import('./components/RestablecerContrasenia/RestablecerContrasenia'),
)
const EnviarDocumento = lazy(() =>
  import('./components/EnviarDocumento/EnviarDocumento'),
)
const ExamenesMedicos = lazy(() =>
  import('./components/ExamenesMedicos/ExamenesMedicos'),
)
const FirmaElectronicaExamen = lazy(() =>
  import('./components/FirmaElectronicaExamen/FirmaElectronicaExamen'),
)
const IMC = lazy(() => import('./components/IMC/IMC'))
const FormularioSalud = lazy(() =>
  import('./components/FormularioSalud/FormularioSalud'),
)
const Capacitaciones = lazy(() =>
  import('./components/Capacitaciones/Capacitaciones'),
)
const Pruebas = lazy(() => import('./components/Pruebas/Pruebas'))

const FirmaElectronicaCapacitacion = lazy(() =>
  import(
    './components/FirmaElectronicaCapacitacion/FirmaElectronicaCapacitacion'
  ),
)
const Beneficios = lazy(() => import('./components/Beneficios/Beneficios'))
const RegistroVacuna = lazy(() =>
  import('./components/RegistroVacuna/RegistroVacuna'),
)
const FirmaElectronicaCompromiso = lazy(() =>
  import('./components/FirmaElectronicaCompromiso/FirmaElectronicaCompromiso'),
)
const Sugerencias = lazy(() => import('./components/Sugerencias/Sugerencias'))
const SolicitarHorario = lazy(() =>
  import('./components/SolicitarHorario/SolicitarHorario'),
)
const ReunionesComite = lazy(() =>
  import('./components/ReunionesComite/ReunionesComite'),
)
const FirmaElectronicaReunion = lazy(() =>
  import('./components/FirmaElectronicaReunion/FirmaElectronicaReunion'),
)
const ReporteFlashAccidentes = lazy(() =>
  import('./components/ReporteFlashAccidentes/ReporteFlashAccidentes'),
)
const MarcacionAsistencia = lazy(() =>
  import('./components/MarcacionAsistencia/MarcacionAsistencia'),
)
const DetalleCapacitacion = lazy(() =>
  import('./components/DetalleCapacitacion/DetalleCapacitacion'),
)
const AccidenteIncidentes = lazy(() =>
  import('./components/AccidenteIncidentes/AccidenteIncidentes'),
)
const DJPrimax = lazy(() => import('./components/DJPrimax/DJPrimax'))
const Nutricion = lazy(() => import('./components/Nutricion/Nutricion'))
const FirmaElectronicaInvestigacion = lazy(() =>
  import(
    './components/FirmaElectronicaInvestigacion/FirmaElectronicaInvestigacion'
  ),
)
const ATS = lazy(() => import('./components/ATS/ATS'))
const CitaReservada = lazy(() =>
  import('./components/CitaReservada/CitaReservada'),
)
const DetalleCita = lazy(() => import('./components/DetalleCita/DetalleCita'))
const CitaCancelar = lazy(() =>
  import('./components/CitaCancelar/CitaCancelar'),
)
const RegistrarCita = lazy(() =>
  import('./components/RegistrarCita/RegistrarCita'),
)

const Inspecciones = lazy(() =>
  import('./components/Inspecciones/Inspecciones'),
)
const DetalleInspeccion = lazy(() =>
  import('./components/DetalleInspeccion/DetalleInspeccion'),
)

const InspeccionRegistrar = lazy(() =>
  import('./components/InspeccionRegistrar/InspeccionRegistrar'),
)
const DetalleInspeccionGeneral = lazy(() =>
  import('./components/DetalleInspeccionGeneral/DetalleInspeccionGeneral'),
)
const InspeccionRealizada = lazy(() =>
  import('./components/InspeccionRealizada/InspeccionRealizada'),
)
const DetalleInspeccionSomma = lazy(() =>
  import('./components/DetalleInspeccionSomma/DetalleInspeccionSomma'),
)
const FirmaInspeccionSomma = lazy(() =>
  import('./components/FirmaInspeccionSomma/FirmaInspeccionSomma'),
)
const ActosYCondiciones = lazy(() =>
  import('./components/ActosYCondiciones/ActosYCondiciones'),
)

const ReportarHallazgo = lazy(() =>
  import('./components/ReportarHallazgo/ReportarHallazgo'),
)
const ReportarActoCondicion = lazy(() =>
  import('./components/ReportarActoCondicion/ReportarActoCondicion'),
)

const ApoyoPsicologico = lazy(() =>
  import('./components/ApoyoPsicologico/ApoyoPsicologico'),
)

// ReactGA.initialize('UA-200166144-1', { debug: true })
// let empresa = localStorage.getItem('EMPRESA')
// ReactGA.event({
//   category: empresa === null ? '' : empresa,
//   action: window.location.pathname,
//   label: 'Pagina visitada',
//   nonInteraction: true,
// })

class App extends React.Component {
  // public onClickCerrarModalMensajeInformativo = (
  //   e: React.FormEvent<HTMLButtonElement>,
  // ) => {
  //   e.preventDefault()

  //   jQuery('#modalMensajeInformativo').css('display', 'none')
  // }

  public render() {
    return (
      <div className="App">
        <BrowserRouter
          basename={ParametrosNoAdministrables.ValoresGenericos.PathBaseName}
        >
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path={'/triaje/registrar'} component={RegistrarDatos} />
              <Route path={'/triaje/diario'} component={TriajeDiario} />
              <Route path={'/triaje/unico'} component={TriajeUnico} />
              <Route path={'/triaje/realizado'} component={TriajeRealizado} />
              <Route path={'/triaje/visorqr'} component={VisorQR} />
              <Route path={'/triaje/documento-qr'} component={DocumentoQR} />
              <Route path={'/triaje/pruebas'} component={Pruebas} />
              <Route
                path={'/triaje/firma-electronica'}
                component={FirmaElectronica}
              />
              <Route
                path={'/triaje/ingreso-contrasenia'}
                component={IngresoContrasenia}
              />
              <Route
                path={'/triaje/restablecer-contrasenia'}
                component={RestablecerContrasenia}
              />
              <Route
                path={'/triaje/enviar-documento'}
                component={EnviarDocumento}
              />
              <Route
                path={'/triaje/declaracion-jurada'}
                component={FirmaElectronica}
              />
              <Route
                path={'/triaje/examenes-medicos'}
                component={ExamenesMedicos}
              />
              <Route
                path={'/triaje/firma-electronica-examen'}
                component={FirmaElectronicaExamen}
              />

              <Route path={'/triaje/imc'} component={IMC} />
              <Route
                path={'/triaje/formulario-salud'}
                component={FormularioSalud}
              />
              <Route path={'/triaje/beneficios'} component={Beneficios} />
              <Route
                path={'/triaje/registrar-vacuna'}
                component={RegistroVacuna}
              />
              <Route path={'/triaje/home'} component={Home} />
              <Route path={'/triaje/sugerencias'} component={Sugerencias} />
              <Route
                path={'/triaje/firma-compromiso'}
                component={FirmaElectronicaCompromiso}
              />
              <Route
                path={'/triaje/solicitar-horario'}
                component={SolicitarHorario}
              />
              <Route
                path={'/triaje/reuniones-comite'}
                component={ReunionesComite}
              />
              <Route
                path={'/triaje/firmar-reunion'}
                component={FirmaElectronicaReunion}
              />
              <Route
                path={'/triaje/reporte-flash-accidente'}
                component={ReporteFlashAccidentes}
              />
              <Route
                path={'/triaje/marcacion-asistencia'}
                component={MarcacionAsistencia}
              />

              <Route
                path={'/triaje/capacitaciones'}
                component={Capacitaciones}
              />

              <Route
                path={'/triaje/firma-electronica-capacitacion'}
                component={FirmaElectronicaCapacitacion}
              />
              <Route
                path={'/triaje/detalle-capacitacion'}
                component={DetalleCapacitacion}
              />

              <Route
                path={'/triaje/accidentes-incidentes'}
                component={AccidenteIncidentes}
              />

              <Route
                path={'/triaje/firmar-investigacion'}
                component={FirmaElectronicaInvestigacion}
              />

              <Route
                path={'/triaje/reportar-acto'}
                component={ActosYCondiciones}
              />
              <Route
                path={'/triaje/reportar-condicion'}
                component={ActosYCondiciones}
              />
              <Route
                path={'/triaje/reportar-fallas-equipo'}
                component={ActosYCondiciones}
              />
              <Route
                path={'/triaje/reportar-oportunidad-mejora'}
                component={ActosYCondiciones}
              />
              <Route
                path={'/triaje/reportar-acto-tiendas'}
                component={ActosYCondiciones}
              />

              <Route
                path={'/triaje/reportar-no-conformidad'}
                component={ActosYCondiciones}
              />

              <Route path={'/triaje/inspecciones'} component={Inspecciones} />

              <Route
                path={'/triaje/inspeccion-registrar'}
                component={InspeccionRegistrar}
              />

              <Route
                path={'/triaje/detalle-inspeccion'}
                component={DetalleInspeccion}
              />

              <Route
                path={'/triaje/detalle-inspeccion-general'}
                component={DetalleInspeccionGeneral}
              />
              <Route
                path={'/triaje/detalle-inspeccion-somma'}
                component={DetalleInspeccionSomma}
              />
              <Route
                path={'/triaje/firma-inspeccion-somma'}
                component={FirmaInspeccionSomma}
              />

              <Route
                path={'/triaje/inspecciones-realizada'}
                component={InspeccionRealizada}
              />

              <Route path={'/triaje/registrar-ats'} component={ATS} />
              <Route path={'/triaje/detalle-ats'} component={ATSDetalle} />
              <Route path={'/triaje/listado-ats'} component={ListadoATS} />

              <Route path={'/triaje/dj-salud'} component={DJPrimax} />
              <Route path={'/triaje/vista-citas'} component={Nutricion} />

              <Route path={'/triaje/detalle-cita'} component={DetalleCita} />
              <Route
                path={'/triaje/cita-reservada'}
                component={CitaReservada}
              />
              <Route path={'/triaje/cita-cancelar'} component={CitaCancelar} />

              <Route
                path={'/triaje/reportar-hallazgo'}
                component={ReportarHallazgo}
              />

              <Route
                path={'/triaje/reportar-acto-condicion'}
                component={ReportarActoCondicion}
              />

              <Route path={'/triaje/agendar-cita'} component={RegistrarCita} />

              <Route
                path={'/triaje/apoyo-psicologico'}
                component={ApoyoPsicologico}
              />

              <Route
                path={'/triaje/listado-hallazgos'}
                component={ListadoHallazgos}
              />

              <Route path={'/triaje/solicitar-epp'} component={SolicitarEPP} />
              <Route
                path={'/triaje/detalle-inspeccion-contratista'}
                component={DetalleInspeccionContratista}
              />

              <Route
                path={'/triaje/detalle-inspeccion-pdf'}
                component={DetalleInspeccionPDF}
              />

              <Route
                path={'/triaje/evolucion-nutricional'}
                component={EvolucionNutricional}
              />

              <Route
                path={'/triaje/actualizar-datos'}
                component={ActualizarDatos}
              />

              <Route path={'/triaje/mis-acciones'} component={MisAcciones} />

              <Route
                path={'/triaje/enviar-aprobacion-mc'}
                component={EnviarAprobacionMC}
              />

              <Route
                path={'/triaje/incidente-detalle'}
                component={IncidenteDetalle}
              />

              <Route path={'/triaje/entrega-epp'} component={EntregaEPP} />

              <Route
                path={'/triaje/estadisticas-racs'}
                component={EstadisticasRACs}
              />
              <Route
                path={'/triaje/registro-petar'}
                component={RegistroPetar}
              />

              <Route
                path={'/triaje/firmar-ats'}
                component={FirmaResponsableATS}
              />

              <Route path={''} component={Login} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    )
  }
}

export default App
